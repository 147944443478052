import { Injectable } from '@angular/core';
import { CryptSecretKeysEnum, LocalStorageKeysEnum, LoginV3Response, SavedUser, SavedUserTokens, SessionStorageKeysEnum, UserV3 } from '../models';
import { SavedUsersService } from './saved-users.service';
import { DEFAULT_LANGUAGE, VerfiyLinkTypeEnum } from 'core-lib';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CoreSessionStorageService {
    constructor(private savedUsersService: SavedUsersService) {}

    getAccountIndexName(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.AccountIndexName) ?? '';
    }

    setAccountIndexName(name: string) {
        sessionStorage.setItem(SessionStorageKeysEnum.AccountIndexName, name);
    }

    getAccountId(): number {
        return +sessionStorage.getItem(SessionStorageKeysEnum.AccountId);
    }

    setAccountId(id: number) {
        sessionStorage.setItem(SessionStorageKeysEnum.AccountId, id.toString());
    }

    removeAccountIndexName(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.AccountIndexName);
    }

    getAccessToken(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.AccessToken);
    }

    getRefreshToken(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.RefreshToken);
    }

    setRefreshToken(value: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.RefreshToken, value);
    }

    setMercureToken(token: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.MercureToken, token);
    }

    getMercureToken(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.MercureToken);
    }

    getQuizToken(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.QuizToken);
    }

    setQuizToken(token: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.QuizToken, token);
    }

    getRedirectUrl(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.RedirectUrl);
    }

    removeRedirectUrl(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.RedirectUrl);
    }
    getUser(): UserV3 {
        const user = sessionStorage.getItem(SessionStorageKeysEnum.User);
        if (!!user) {
            const bytes = CryptoJS.AES.decrypt(user, CryptSecretKeysEnum.User);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
            return null;
        }
    }

    setUser(user: UserV3): void {
        let cripted = CryptoJS.AES.encrypt(JSON.stringify(user), CryptSecretKeysEnum.User).toString();
        sessionStorage.setItem(SessionStorageKeysEnum.UserId, user?.id?.toString());
        sessionStorage.setItem(SessionStorageKeysEnum.User, cripted);
    }

    setIsFirstLogin(value: boolean): void {
        sessionStorage.setItem(SessionStorageKeysEnum.IsFirstLogin, String(value));
    }

    getIsFirstLogin(): boolean {
        return JSON.parse(sessionStorage.getItem(SessionStorageKeysEnum.IsFirstLogin));
    }

    getUserId(): number {
        const userId = sessionStorage.getItem(SessionStorageKeysEnum.UserId);
        return +userId;
    }

    getIsLoggedIn(): boolean {
        const isLoggedIn = sessionStorage.getItem(SessionStorageKeysEnum.IsLoggedIn);
        if (isLoggedIn === 'true') {
            return true;
        } else {
            return false;
        }
    }

    setRedirectUrl(value: string) {
        sessionStorage.setItem(SessionStorageKeysEnum.RedirectUrl, value);
    }

    getLocale(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.Locale) || DEFAULT_LANGUAGE;
    }

    setLocale(locale: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.Locale, locale);
    }

    getIsIncognitoSession(): boolean {
        return this.getVerifyLinkType() !== VerfiyLinkTypeEnum.NORMAL;
    }

    getAnonIncognito(): boolean {
        return this.getVerifyLinkType() === VerfiyLinkTypeEnum.INCOGNITO;
    }

    setVerifyLinkType(value: VerfiyLinkTypeEnum): void {
        sessionStorage.setItem(SessionStorageKeysEnum.VerfiyLinkTypeEnum, value);
    }

    removeVerifyLinkType(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.VerfiyLinkTypeEnum);
    }

    getVerifyLinkType(): VerfiyLinkTypeEnum {
        return sessionStorage.getItem(SessionStorageKeysEnum.VerfiyLinkTypeEnum) as VerfiyLinkTypeEnum;
    }

    setUserTokens(accessToken: string, refreshToken: string, mercureToken: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.AccessToken, accessToken);
        sessionStorage.setItem(SessionStorageKeysEnum.MercureToken, mercureToken);
        sessionStorage.setItem(SessionStorageKeysEnum.RefreshToken, refreshToken);
    }
    removeUserTokens(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.AccessToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.MercureToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.RefreshToken);
    }

    getPrimaryColor(): string {
        return sessionStorage.getItem(SessionStorageKeysEnum.PrimaryColor);
    }

    setPrimaryColor(value: string): void {
        sessionStorage.setItem(SessionStorageKeysEnum.PrimaryColor, value);
    }

    setIsCollapsedMenu(value: boolean): void {
        sessionStorage.setItem(SessionStorageKeysEnum.CollapsedMenu, String(value));
    }

    getIsCollapsedMenu(): boolean {
        return JSON.parse(sessionStorage.getItem(SessionStorageKeysEnum.CollapsedMenu));
    }
    setSessionStorageData(userDetails: LoginV3Response): void {
        /*
         *   Remove old data before setting
         */

        this.removeSessionStorageData();

        sessionStorage.setItem(SessionStorageKeysEnum.IsLoggedIn, 'true');
        sessionStorage.setItem(SessionStorageKeysEnum.AccessToken, userDetails.token_key);
        sessionStorage.setItem(SessionStorageKeysEnum.MercureToken, userDetails.mercure_token);
        this.setUser(userDetails.user);
        sessionStorage.setItem(SessionStorageKeysEnum.RefreshToken, userDetails.refresh_token);
        sessionStorage.setItem(SessionStorageKeysEnum.UserId, JSON.stringify(userDetails.user?.id));
        sessionStorage.setItem(SessionStorageKeysEnum.AccountIndexName, userDetails?.user?.account?.index_name);
        sessionStorage.setItem(SessionStorageKeysEnum.AccountId, userDetails?.user?.account?.id.toString());
        if (userDetails?.user?.locale) sessionStorage.setItem(SessionStorageKeysEnum.Locale, userDetails?.user?.locale);

        const userExistsInLs: boolean = this.savedUsersService.checkIfUserExists(userDetails.user?.id);

        const savedUserTokens: SavedUserTokens = {
            access_token: userDetails.token_key,
            refresh_token: userDetails.refresh_token
        };
        const user: SavedUser = {
            user: userDetails.user,
            tokens: savedUserTokens,
            lastLogin: new Date().getTime()
        };
        if (!userExistsInLs) {
            this.savedUsersService.addNewEntry(user);
        } else {
            this.savedUsersService.updateUser(userDetails.user);
            this.savedUsersService.updateUserTokens(userDetails.user?.id, savedUserTokens);
            this.savedUsersService.updateUserLastLogin(userDetails.user?.id, new Date());
        }
    }

    removeSessionStorageData(): void {
        // @todo-j see if we can use sessionStorage.clear();
        sessionStorage.removeItem(SessionStorageKeysEnum.AccessToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.MercureToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.RefreshToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.User);
        sessionStorage.removeItem(SessionStorageKeysEnum.UserId);

        sessionStorage.removeItem(SessionStorageKeysEnum.InviteToken);
        sessionStorage.removeItem(SessionStorageKeysEnum.InviteEmail);
        sessionStorage.removeItem(SessionStorageKeysEnum.AccountIndexName);
        sessionStorage.removeItem(SessionStorageKeysEnum.RestrictedDomains);
        /*
         *   Set session storage to logged out for refresh mechanism
         */
        sessionStorage.setItem(SessionStorageKeysEnum.IsLoggedIn, 'false');
    }

    setRestrictedDomains(domains: string[]): void {
        sessionStorage.setItem(SessionStorageKeysEnum.RestrictedDomains, JSON.stringify(domains));
    }

    getRestrictedDomains(): string[] {
        return JSON.parse(sessionStorage.getItem(SessionStorageKeysEnum.RestrictedDomains));
    }

    removeRestrictedDomains(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.RestrictedDomains);
    }

    setTableColumns(viewId: string | number, columns: string[]): void {
        let savedColumns = this.getTableColumns();
        if (!!columns?.length) {
            savedColumns[viewId] = columns;
        }
        sessionStorage.setItem(SessionStorageKeysEnum.TableColumns, JSON.stringify(savedColumns));
    }
    removeViewTableColumn(viewId: string | number): void {
        let savedColumns = this.getTableColumns();
        delete savedColumns[viewId];
        sessionStorage.setItem(SessionStorageKeysEnum.TableColumns, JSON.stringify(savedColumns));
    }
    getTableColumns(): { [key: string]: string[] } {
        const columns = sessionStorage.getItem(SessionStorageKeysEnum.TableColumns);
        if (!!columns) return JSON.parse(columns);

        return {};
    }
    removeTableColumns(): void {
        sessionStorage.removeItem(SessionStorageKeysEnum.TableColumns);
    }

    setIsUserFirstLogin(userId: number): void {
        let obj = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.IsFirstLogin)) ?? {};
        obj[userId] = true;
        localStorage.setItem(LocalStorageKeysEnum.IsFirstLogin, JSON.stringify(obj));
    }
    removeIsUserFirstLogin(userId: number): void {
        let obj = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.IsFirstLogin)) ?? {};
        delete obj[userId];
        localStorage.setItem(LocalStorageKeysEnum.IsFirstLogin, JSON.stringify(obj));
    }
    getIsUserFirstLogin(userId: number): boolean {
        const obj = JSON.parse(localStorage.getItem(LocalStorageKeysEnum.IsFirstLogin)) ?? {};
        return !!obj[userId];
    }
}
