import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';

import { AccountThemeModel } from './tailwind-theme.model';
import { updateThemeVariables } from './tailwind.util';
import { CoreSessionStorageService } from '../common';
import { CORE_LIB_CONFIG, CoreLibConfig } from '../../core-lib.config';
import { Store } from '@ngrx/store';
import { ThemeActions } from 'core-lib';

const theme: AccountThemeModel = {
    colors: {
        primary: '#FF005C'
    },
    login: {
        bottomColor: '#b0ff02',
        topColor: '#ff005a'
    }
};

@Injectable({
    providedIn: 'root'
})
export class TailwindThemeService {
    accountLogo: string = 'assets/logo/logo-platform-only.png';
    customAccountLogo: string;
    logoLinkURL: string = 'https://deepersignals.com';
    primary: string;
    terms1: { text: string; url: string };
    terms2: { text: string; url: string };
    defaultPrivacyPolicy: string;
    ssoUrl: string;
    accountName: string;
    loginColors: { topColor: string; bottomColor: string };

    whiteListRoutes: string[] = ['login', 'sso', 'magic', 'share', 'course', 'self-sign-up', 'reset-password'];

    constructor(
        private _http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        private location: Location,
        private store: Store,
        private sessionStorageService: CoreSessionStorageService,
        @Inject(CORE_LIB_CONFIG) private config: CoreLibConfig
    ) {}
    loadConfig(accountName?: string) {
        const accountNameFromURL = this.getAccountNameFromURL();
        if (!_.isEmpty(accountNameFromURL) && !_.isNull(accountNameFromURL)) {
            this.sessionStorageService.setAccountIndexName(accountNameFromURL);
            accountName = accountNameFromURL;
        } else {
            const accountFromSession = this.sessionStorageService.getAccountIndexName();
            if (!_.isEmpty(accountFromSession) && !_.isNull(accountFromSession)) {
                accountName = accountFromSession;
            } else {
                accountName = 'deepersignals';
            }
        }

        const configUrl = `${this.config.apiUrl}public/accounts/theme/${accountName}`;
        return this._http
            .get<AccountThemeModel>(`${configUrl}`)
            .toPromise()
            .then((themeData: AccountThemeModel) => {
                if (!!themeData) {
                    this.updateThemeData(themeData);
                }
            })
            .catch((err: any) => {
                this.sessionStorageService.removeAccountIndexName();
                this.primary = theme.colors.primary;
                updateThemeVariables(theme, this.document);
                this.location.go('/login');
            });
    }

    updateThemeData(themeData: AccountThemeModel): void {
        this.store.dispatch(ThemeActions.updateTheme({ request: themeData }));
        if (themeData.image) {
            this.accountLogo = themeData.image;
            this.customAccountLogo = themeData.image;
        }
        if (themeData.account_name) {
            this.accountName = themeData.account_name;
        }
        this.ssoUrl = themeData.sso_url;
        if (themeData.additional_terms) {
            this.terms1 = {
                text: themeData.additional_terms.text,
                url: themeData.additional_terms.url
            };
            this.terms2 = {
                text: themeData.additional_terms.text2,
                url: themeData.additional_terms.url2
            };
            this.defaultPrivacyPolicy = themeData?.default_privacy_policy;
        }
        this.loginColors = themeData.login;
        if (themeData.colors?.primary) {
            this.primary = themeData.colors.primary;
            this.sessionStorageService.setPrimaryColor(themeData.colors.primary);
            updateThemeVariables(themeData, this.document);
        } else {
            this.primary = theme.colors.primary;
            updateThemeVariables(theme, this.document);
        }
    }

    updateThemeLogo(logo: string): void {
        this.store.dispatch(ThemeActions.updateThemeLogo({ logo }));
        this.accountLogo = logo;
        this.customAccountLogo = logo;
    }

    getAccountNameFromURL(): string {
        const url = this.location.path(); // Gets the URL path
        const queryParams = new URLSearchParams(url.split('?')[1]); // Parses query string
        return queryParams.get('account'); // Gets the 'account' parameter value
    }
}
