import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DsNotification, DsNotificationTypeEnum } from '../ds-notification.models';
import { DsNotificationsService } from '../ds-notification.service';
import { NgIf, NgStyle } from '@angular/common';
import { DsIconComponent, DsIconEnum } from '../../ds-icon';
import { Router } from '@angular/router';
import { TransitionDirective, TransitionElementDirective } from '../../ds-transition';

@Component({
    selector: 'ds-notification',
    templateUrl: './ds-notification.component.html',
    standalone: true,
    imports: [NgStyle, NgIf, DsIconComponent, TransitionElementDirective, TransitionDirective],
    host: {
        class: 'w-full'
    }
})
export class DsNotificationComponent {
    DsIconEnum = DsIconEnum;
    DsNotificationTypeEnum = DsNotificationTypeEnum;
    @Input()
    notification: DsNotification;

    @Input() index: number;

    @ViewChild('notificationRef') notificationRef: ElementRef;

    constructor(
        private _notificationsService: DsNotificationsService,
        private router: Router,
        private el: ElementRef
    ) {}

    pause(): void {
        this.notification.paused.next(true);
    }

    unPause(): void {
        this.notification.paused.next(false);
    }

    remove(): void {
        this._notificationsService.removeNotification(this.notification.id);
    }

    redirect(url: string): void {
        this.router.navigate([url]);
    }
}
