import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'shared-components-lib';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },

    {
        path: 'login',
        loadChildren: () => import('./../modules/login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./../modules/register/register.routes').then((m) => m.REGISTER_ROUTES)
    },
    {
        path: 'verify',
        loadChildren: () => import('./../modules/verify/verify.module').then((m) => m.VerifyModule)
    },
    {
        path: 'logout',
        loadChildren: () => import('./../modules/logout/logout.module').then((m) => m.LogoutModule)
    },
    {
        path: 'magic',
        loadChildren: () => import('./../modules/magic-link/magic-link.module').then((m) => m.MagicLinkModule)
    },
    {
        path: 'sso/:token',
        loadChildren: () => import('./../modules/sso/sso.module').then((m) => m.SsoModule)
    },
    {
        path: 'self-sign-up',
        loadChildren: () => import('../modules/self-sign-up/self-sign-up.routes').then((m) => m.SELF_SIGN_UP_ROUTES)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./../modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
    },
    {
        path: 'create-password',
        loadChildren: () => import('./../modules/create-password/create-password.module').then((m) => m.CreatePasswordModule)
    },
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
