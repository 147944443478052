import tinycolor from 'tinycolor2';
import { AccountThemeModel, Color } from './tailwind-theme.model';
import { TailwindThemeService } from 'core-lib';

export function computeColorPalette(hex: string): Color[] {
    return [
        getColorObject(tinycolor(hex).lighten(45), '50'),
        getColorObject(tinycolor(hex).lighten(40), '100'),
        getColorObject(tinycolor(hex).lighten(30), '200'),
        getColorObject(tinycolor(hex).lighten(20), '300'),
        getColorObject(tinycolor(hex).lighten(10), '400'),
        getColorObject(tinycolor(hex), '500'),
        getColorObject(tinycolor(hex).darken(3), '600'),
        getColorObject(tinycolor(hex).darken(6), '700'),
        getColorObject(tinycolor(hex).darken(9), '800'),
        getColorObject(tinycolor(hex).darken(12), '900')
    ];
}
export function getColorObject(value: tinycolor.Instance, name: string): Color {
    const c = tinycolor(value);
    return {
        name,
        hex: c.toHexString(),
        isDarkContrast: c.isLight()
    };
}
export function updateThemeVariables(theme: AccountThemeModel, document: Document) {
    if (!!theme.colors) {
        for (const [name, color] of Object.entries(theme.colors)) {
            const palette = computeColorPalette(color);
            for (const variant of palette) {
                document.documentElement.style.setProperty(`--${name}-${variant.name}`, variant.hex);
            }
        }
    }

    if (!!theme.login) {
        for (const [name, value] of Object.entries(theme.login)) {
            let rgb = hexToRgb(value);
            document.documentElement.style.setProperty(`--login-${name}`, `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);
            document.documentElement.style.setProperty(`--report-${name}`, `rgb(${rgb.r}, ${rgb.g}, ${rgb.b},0.16)`);
        }
    }

    if (theme?.colors?.primary) {
        document.documentElement.style.setProperty(`--primaryRgbA-0`, hexToRgbA(theme.colors.primary, true));
        document.documentElement.style.setProperty(`--primaryRgbA-1`, getColorObject(tinycolor(theme.colors.primary).darken(12), '900').hex);
    }
}

export function hexToRgbA(hex, zero: boolean) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        if (zero) {
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)';
        } else {
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
    }
    throw new Error('Bad Hex');
}
export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
}
export function initTailwindThemeConfig(tailwindThemeSvc: TailwindThemeService) {
    return () => tailwindThemeSvc.loadConfig(null);
}
