import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ServerSideEventsModel, ServerSubscriptionMessage } from '../models/server-side-events.model';
import { MercureActions } from 'core-lib';

export const mercureFeatureKey = 'mercure';

export interface MercureState {
    subscriptions: ServerSideEventsModel[];
    subscriptionMessage: ServerSubscriptionMessage;
    downloadCenterUpdate: boolean;
    userUpdated: boolean;
    accountDeleted: boolean;
    accountUpdated: boolean;
    subscriptionCanceled: boolean;
    mercureTokenRefreshed: boolean;
}

export const initialState: MercureState = {
    subscriptions: [],
    subscriptionMessage: null,
    downloadCenterUpdate: null,
    userUpdated: null,
    accountDeleted: null,
    accountUpdated: null,
    subscriptionCanceled: null,
    mercureTokenRefreshed: null
};

export const selectMercureState = createFeatureSelector<MercureState>(mercureFeatureKey);

export const subscriptions = createSelector(selectMercureState, (state) => state.subscriptions);
export const subscriptionMessage = createSelector(selectMercureState, (state) => state.subscriptionMessage);
export const downloadCenterUpdate = createSelector(selectMercureState, (state) => state.downloadCenterUpdate);
export const userUpdated = createSelector(selectMercureState, (state) => state.userUpdated);
export const accountDeleted = createSelector(selectMercureState, (state) => state.accountDeleted);
export const accountUpdated = createSelector(selectMercureState, (state) => state.accountUpdated);
export const selectSubscriptionCanceled = createSelector(selectMercureState, (state) => state.subscriptionCanceled);
export const selectMercureTokenRefreshed = createSelector(selectMercureState, (state) => state.mercureTokenRefreshed);

export const mercureReducer = createReducer(
    initialState,
    on(MercureActions.serverMessageReceived, (state, { message }) => ({
        ...state,
        subscriptionMessage: message
    })),
    on(MercureActions.addSubscription, (state, { subscription }) => {
        // Check for duplicates before adding a new subscription
        const isDuplicate = state.subscriptions.some((sub) => sub.subscribedUrl === subscription.subscribedUrl);

        if (!isDuplicate) {
            return {
                ...state,
                subscriptions: [...state.subscriptions, subscription]
            };
        }

        return state;
    }),
    on(MercureActions.closeConnections, (state, { events }) => {
        let subs = [...state.subscriptions];

        events.forEach((event) => {
            event.event.close();
            subs = subs.filter((sub) => sub.subscribedUrl !== event.subscribedUrl);
        });

        return {
            ...state,
            subscriptions: [...subs]
        };
    }),
    on(MercureActions.closeConnection, (state, { ssEvent }) => {
        if (ssEvent.event) {
            ssEvent.event.close();
            return {
                ...state,
                subscriptions: state.subscriptions.filter((sub) => sub.subscribedUrl !== ssEvent.subscribedUrl)
            };
        }
        return { ...state };
    }),
    on(MercureActions.downloadCenterUpdate, (state, { response }) => ({
        ...state,
        downloadCenterUpdate: response
    })),
    on(MercureActions.userUpdated, (state, { response }) => ({
        ...state,
        userUpdated: response
    })),
    on(MercureActions.accountDeleted, (state, { response }) => ({
        ...state,
        accountDeleted: response
    })),
    on(MercureActions.accountUpdated, (state, { response }) => ({
        ...state,
        accountUpdated: response
    })),
    on(MercureActions.subscriptionCanceled, (state, { response }) => ({
        ...state,
        subscriptionCanceled: response
    })),
    on(MercureActions.refreshMerqureTokenReqSuccess, (state, { response }) => ({
        ...state,
        mercureTokenRefreshed: response
    })),
    on(MercureActions.refreshMerqureTokenReqFailure, (state, { error }) => ({
        ...state,
        mercureTokenRefreshed: false
    }))
);
