import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { DsButtonTypeEnum } from './ds-button.models';
import { DsIconComponent, DsIconEnum, DsIconWeightEnum } from '../ds-icon';

@Component({
    selector: 'ds-button',
    templateUrl: './ds-button.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgTemplateOutlet, DsIconComponent],
    host: {
        '[class]': 'hostClasses'
    }
})
export class DsButtonComponent {
    //TODO: Update to angular 17 input
    DsButtonTypeEnum = DsButtonTypeEnum;
    @Input() name: string;
    @Input() hostClasses: string;
    @Input() icon: DsIconEnum;
    @Input() iconSize: string = 'text-lg';
    @Input() iconWeight: DsIconWeightEnum = DsIconWeightEnum.Light;
    @Input() iconOnlyButton: boolean;
    @Input() iconClasses: string;
    @Input() iconPositionStart: boolean = false;
    @Input() type: DsButtonTypeEnum = DsButtonTypeEnum.Primary;
    @Input() size: 'xs' | 's' | 'm' | 'l' | 'xl' = 'm';
    //todo: we should remove class input in future
    @Input() class: string;
    //putting buttonClasses because class is reserved word and don't want to break other places
    @Input() buttonClasses: string;
    @Input() disabled: boolean;
    @Input() disableFocus: boolean = false;
    @Input() disableBorder: boolean = false;
    @Input() textClasses: string;
    @Input() loading: boolean = false;

    // For report
    isIconSvg = input<boolean>(false);
    @Input() svgSize: number = 22;
    @Input() svgStroke: number = 1;

    @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private sanitizer: DomSanitizer) {}

    parseIcon(svg: string) {
        return this.sanitizer.bypassSecurityTrustHtml(svg);
    }

    onButtonClick() {
        if (!this.disabled) {
            this.onClick.emit(true);
        }
    }

    getFullBtnClasses(): string[] {
        const btnClasses = [this.getButtonSizeClasses()];
        if (!!this.class) {
            btnClasses.push(this.class);
        }
        if (this.disabled) {
            btnClasses.push('cursor-not-allowed bg-gray-300');
        } else {
            if (this.type !== 'secondary') {
                btnClasses.push('bg-primary-600 hover:bg-primary-700');
            }
        }
        if (!this.disableFocus) {
            btnClasses.push('focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500');
        }
        if (this.disableBorder) {
            btnClasses.push('border border-transparent');
        }

        return btnClasses;
    }

    getFullButtonIconClasses(): string[] {
        const btnClasses = [this.getButtonIconSizeClasses()];
        if (!!this.class) {
            btnClasses.push(this.class);
        }
        if (this.disabled) {
            btnClasses.push('cursor-not-allowed bg-gray-300');
        } else {
            if (this.type !== 'secondary') {
                btnClasses.push('bg-primary-600 hover:bg-primary-700');
            }
        }
        if (!this.disableFocus) {
            btnClasses.push('focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500');
        }
        if (this.disableBorder) {
            btnClasses.push('border border-transparent');
        }
        return btnClasses;
    }

    getFullOnlyIconBtnClasses(): string[] {
        const btnClasses = [this.getOnlyIconButtonClasses()];
        if (!!this.class) {
            btnClasses.push(this.class);
        }
        if (this.disabled) {
            btnClasses.push('cursor-not-allowed bg-gray-300');
        } else {
            if (this.type !== 'secondary') {
                btnClasses.push('bg-primary-600 hover:bg-primary-700');
            }
        }
        if (!this.disableFocus) {
            btnClasses.push('focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500');
        }
        if (this.disableBorder) {
            btnClasses.push('border border-transparent');
        }
        return btnClasses;
    }

    getButtonSizeClasses() {
        switch (this.size) {
            case 'xs':
                return 'px-2.5 py-1.5 text-xs';
            case 's':
                return 'px-3 py-2 text-sm';
            case 'm':
                return 'px-4 py-2 text-sm';
            case 'l':
                return 'px-4 py-2 text-base';
            case 'xl':
                return 'px-6 py-3 text-base';
            default:
                return '';
        }
    }

    getButtonIconSizeClasses() {
        switch (this.size) {
            case 'xs':
                return 'px-3 py-2 text-sm leading-4';
            case 's':
                return 'px-4 py-2 text-sm';
            case 'm':
                return 'px-2 py-1 text-sm md:px-3 md:py-2 lg:px-4 md:text-base';
            case 'l':
                return 'px-6 py-3 text-base';
            case 'xl':
                return 'px-6 py-3 text-base';
            default:
                return 'px-4 py-2 text-sm';
        }
    }

    getIconClass() {
        switch (this.size) {
            case 'xs':
                return 'flex items-center justify-center mr-2 h-4 w-4';
            case 's':
                return 'flex items-center justify-center mr-2 h-5 w-5';
            case 'm':
                return 'flex items-center justify-center mr-3 h-5 w-5';
            case 'l':
                return 'flex items-center justify-center mr-3 h-5 w-5';
            case 'xl':
                return 'flex items-center justify-center mr-3 h-5 w-5';
            default:
                return 'flex items-center justify-center ml-2 h-4 w-4';
        }
    }

    getOnlyIconButtonClasses() {
        switch (this.size) {
            case 'xs':
                return 'p-1';
            case 's':
                return 'p-1.5';
            case 'm':
                return 'p-2';
            case 'l':
                return 'p-2';
            case 'xl':
                return 'p-3';
            default:
                return '';
        }
    }

    getOnlyIconButtonIconClasses() {
        switch (this.size) {
            case 'xs':
                return 'flex items-center justify-center h-5 w-5';
            case 's':
                return 'flex items-center justify-center h-5 w-5';
            case 'm':
                return 'flex items-center justify-center h-5 w-5';
            case 'l':
                return 'flex items-center justify-center h-6 w-6';
            case 'xl':
                return 'flex items-center justify-center h-6 w-6';
            default:
                return 'flex items-center justify-center h-5 w-5';
        }
    }
}
