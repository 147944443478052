<!--Using ng-option and for loop-->
<div class="relative" [ngClass]="selectClasses" *ngIf="!enableAddOnSearch">
    <label class="block text-sm font-medium text-gray-700 mb-1" [for]="name">{{ name }}</label>
    <ng-select
        #select
        class="ds-select"
        [ngClass]="{
            showPillSearch: showPillSearch,
            'disabled !cursor-not-allowed': disabled || _disabled
        }"
        [items]="filteredOptions() | sortByName: nameField : disableNameOrderCheck() | activeOnTop: _value : valueField : multiple"
        [bindLabel]="nameField"
        [bindValue]="valueField"
        [multiple]="multiple"
        [maxSelectedItems]="maxSelectedItems"
        [searchable]="showPillSearch"
        [closeOnSelect]="!multiple"
        [placeholder]="placeholder"
        [clearable]="showPillSearch"
        [(ngModel)]="_value"
        [loading]="loading"
        [hideSelected]="hideSelected"
        [clearSearchOnAdd]="showPillSearch"
        [deselectOnClick]="deselectOnClick"
        (close)="applyValue(_value)"
        (change)="onChangeFn()"
        (focus)="_onTouched($event)">
        <ng-template ng-header-tmp *ngIf="showSearch && !showPillSearch">
            <input
                type="text"
                placeholder="Search"
                [value]="searchTerm()"
                class="w-full shadow-sm border-b border-gray-200 border-t-0 border-l-0 border-r-0 focus:border-gray-200 focus:outline-none focus:ring-transparent outline-none ring-transparent"
                (input)="getSelectInput($event.target.value)"
                *ngIf="showSearch && !showPillSearch" />
        </ng-template>
        <ng-template ng-option-tmp let-item="item$" *ngIf="!showPillSearch">
            <div class="flex justify-between">
                <div class="flex">
                    <img class="mr-2 h-6 w-6" *ngIf="item?.value?.iconPath" [src]="item?.value?.iconPath" alt="icon" />
                    <div class="whitespace-break-spaces">
                        {{ item.label }}
                        <span class="flex text-sm font-light leading-5 whitespace-pre-wrap" *ngIf="item?.value?.description">{{ item.value.description }}</span>
                    </div>
                </div>

                <svg *ngIf="item.selected && enableCheckmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!showPillSearch">
            <!-- CUSTOM TEMPLATE TO SHOW MULTIPLE PLACEHOLDER WITH ARROWS -->
            <div class="flex items-center justify-between w-full">
                <div class="ng-value" *ngIf="items.length > 0 && hasValueHidden">
                    <span class="ng-value-label placeholder-text">{{ placeholder }}</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 0 && !hasValueHidden">
                    <span class="ng-value-label selected-value">{{ items.length }} selected</span>
                </div>
            </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" *ngIf="showPillSearch && disabled">
            <span class="ng-value-label whitespace-break-spaces">{{ item }}</span>
        </ng-template>
    </ng-select>

    <ds-input-errors [control]="control"></ds-input-errors>
</div>

<div class="relative" [ngClass]="selectClasses" *ngIf="enableAddOnSearch">
    <label class="block text-sm font-medium text-gray-700 mb-1" [for]="name">{{ name }}</label>
    <ng-select
        #select
        class="ds-select"
        [ngClass]="{ showPillSearch: showPillSearch, disabled: disabled }"
        [items]="filteredOptions() | sortByName: nameField : disableNameOrderCheck() | activeOnTop: _value : valueField : multiple"
        [bindLabel]="nameField"
        [bindValue]="valueField"
        [multiple]="multiple"
        [maxSelectedItems]="maxSelectedItems"
        [searchable]="showPillSearch"
        [addTag]="addTag.bind(this)"
        [closeOnSelect]="!multiple"
        [placeholder]="placeholder"
        [clearable]="showPillSearch"
        [(ngModel)]="_value"
        [disabled]="disabled"
        [loading]="loading"
        [hideSelected]="hideSelected"
        [clearSearchOnAdd]="showPillSearch"
        [deselectOnClick]="deselectOnClick"
        (close)="applyValue(_value)"
        (change)="onChangeFn()"
        (focus)="_onTouched($event)">
        <ng-template ng-header-tmp *ngIf="showSearch && !showPillSearch">
            <input
                type="text"
                placeholder="Search"
                [value]="searchTerm()"
                class="w-full shadow-sm border-b border-gray-200 border-t-0 border-l-0 border-r-0 focus:border-gray-200 focus:outline-none focus:ring-transparent outline-none ring-transparent"
                (input)="getSelectInput($event.target.value)"
                *ngIf="showSearch && !showPillSearch" />
        </ng-template>
        <ng-template ng-option-tmp let-item$="item$" *ngIf="!showPillSearch">
            <div class="flex justify-between">
                <div class="flex">
                    <img class="mr-2 h-6 w-6" *ngIf="item$?.value?.iconPath" [src]="item$?.value?.iconPath" alt="icon" />
                    {{ item$.label }}
                </div>

                <svg *ngIf="item$.selected && enableCheckmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="!showPillSearch">
            <!-- CUSTOM TEMPLATE TO SHOW MULTIPLE PLACEHOLDER WITH ARROWS -->
            <div class="flex items-center justify-between w-full">
                <div class="ng-value" *ngIf="items.length > 0 && hasValueHidden">
                    <span class="ng-value-label placeholder-text">{{ placeholder }}</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 0 && !hasValueHidden">
                    <span class="ng-value-label selected-value">{{ items.length }} selected</span>
                </div>
            </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" *ngIf="showPillSearch && disabled">
            <span class="ng-value-label whitespace-break-spaces">{{ item }}</span>
        </ng-template>
    </ng-select>
</div>
