import { HttpErrorResponse } from '@angular/common/http';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CryptSecretKeysEnum, SessionStorageKeysEnum, UserActions, UserRolesEnum, UserV3 } from 'core-lib';
import * as CryptoJS from 'crypto-js';

export const userFeatureKey = 'user';

const getUserFromSession = () => {
    try {
        const user = sessionStorage.getItem(SessionStorageKeysEnum.User);
        if (user !== 'undefined') {
            const bytes = CryptoJS.AES.decrypt(user, CryptSecretKeysEnum.User);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
            return null;
        }
    } catch {
        return null;
    }
};

export interface UserState {
    user: UserV3;
    error: HttpErrorResponse;
    loading: boolean;
}

export const initialState: UserState = {
    user: getUserFromSession(),
    error: null,
    loading: false
};

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);
export const selectUser = createSelector(selectUserState, (state) => state.user);

export const selectUserPermissions = createSelector(selectUserState, (state) => state.user?.abac_role?.attributes);

export const selectIsPlatformAdmin = createSelector(selectUserState, (state) => state.user?.roles?.includes(UserRolesEnum.PlatformAdmin));
export const selectIsAdmin = createSelector(selectUserState, (state) => state.user?.roles?.includes(UserRolesEnum.Admin));
export const selectIsDeveloper = createSelector(selectUserState, (state) => state.user?.roles?.includes(UserRolesEnum.Developer));

export const userHasRoles$ = (roles: string[]) =>
    createSelector(selectUserState, (state) => {
        return state.user?.roles?.some((r) => roles.indexOf(r) >= 0);
    });

export const userHasPermission$ = (permission: string) =>
    createSelector(selectUserState, selectIsPlatformAdmin, (state: UserState, isPlatformAdmin: boolean) => {
        if (isPlatformAdmin) return true;
        return state.user?.abac_role?.attributes?.some((p) => p.property === permission);
    });

export const userHasPermissions$ = (permissions: string[]) =>
    createSelector(selectUserState, selectIsPlatformAdmin, (state: UserState, isPlatformAdmin: boolean) => {
        if (isPlatformAdmin) return true;
        return permissions.every((p) => state.user?.abac_role?.attributes?.map((up) => up?.property)?.includes(p));
    });

export const selectUserLoading = createSelector(selectUserState, (state) => state.loading);
export const selectUserError = createSelector(selectUserState, (state) => state.error);

export const userReducer = createReducer(
    initialState,
    on(UserActions.getUser, (state, {}) => ({
        ...state,
        loading: true
    })),
    on(UserActions.getUserSuccess, (state, { response }) => ({
        ...state,
        user: response,
        loading: false
    })),
    on(UserActions.getUserFailure, (state, { error }) => ({
        ...state,
        error: error,
        loading: false
    }))
);
