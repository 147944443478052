<main
    class="min-h-screen bg-cover bg-top sm:bg-top"
    style="background-image: url('https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75')">
    <div class="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
        <p class="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">
            {{ 'errorPages.fourZeroFour' | translate }}
        </p>
        <h1 class="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl" *ngIf="previousUrl">
            {{ 'errorPages.looksLikeYouTriedToGoTo' | translate }}
            <code>{{ previousUrl }}</code> but that route doesn't exist.
        </h1>
        <p class="mt-2 text-lg font-medium text-black text-opacity-50" *ngIf="!linkNoLongerActive && !message">
            {{ 'errorPages.whatBringsYouHereOnThisDay' | translate }}
        </p>
        <p class="mt-2 text-lg font-medium text-black text-opacity-50" *ngIf="linkNoLongerActive && !message">
            {{ 'errorPages.linkNoLongerActive' | translate }}
        </p>
        <p class="mt-2 text-lg font-medium text-black text-opacity-50" *ngIf="message">
            {{ message }}
        </p>
        <div class="mt-6">
            <a [routerLink]="['/login']" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50" *ngIf="!isAnon()">
                {{ 'errorPages.backToLogin' | translate }}
            </a>
            <a
                href="https://deepersignals.com"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
                *ngIf="isAnon()">
                {{ 'errorPages.goBackToSafety' | translate }}
            </a>
        </div>
    </div>
</main>
