import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CORE_LIB_CONFIG, CoreLibConfig } from 'core-lib';
import { RefreshTokenReq } from 'projects/auth/src/common/models/auth.models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor(
        private http: HttpClient,
        @Inject(CORE_LIB_CONFIG) private config: CoreLibConfig
    ) {}

    refreshTokenReq(refreshTokenReq: RefreshTokenReq): Observable<any> {
        return this.http.post<any>(`${this.config.apiUrl}token/refresh`, { ...refreshTokenReq });
    }
}
