import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'activeOnTop',
    pure: true,
    standalone: true
})
export class DsSelectActiveOnTopPipe implements PipeTransform {
    //array type is any because we don't specificly know what type of array we get.
    transform(array: any[], selectedValues: (string | number)[], valueField: string, isMultiple: boolean): any[] {
        if (!isMultiple || !selectedValues || !array?.length) return array;

        array = _.sortBy(array, [
            function (o) {
                return selectedValues.indexOf(o[valueField]) === -1;
            }
        ]);
        return array;
    }
}
