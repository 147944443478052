import * as Sentry from '@sentry/browser';
import { UserV3 } from '../models';
import { IntercomConfig, getIntercomConfig } from '../../core-lib.config';

// Intercom docs
//https://developers.intercom.com/installing-intercom/web/installation/

export function changeZIndex(newZIndex) {
    // Select all elements with class name starting with "intercom"
    const elements = document.querySelectorAll('.intercom-app [class^="intercom"]');

    // Iterate through the elements
    elements.forEach((element) => {
        // Get the current z-index value
        const currentZIndex = window.getComputedStyle(element).zIndex;

        // Check if z-index is not 'auto' (indicating it has a specific z-index value)
        if (currentZIndex !== 'auto') {
            // Change the z-index to the new value
            element['style']['zIndex'] = newZIndex;
        }
    });
}

export const intercomActions = {
    boot: (user?: UserV3) => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('shutdown');
                if (!!user) {
                    window.Intercom('boot', {
                        api_base: (INTERCOM_CONFIG as IntercomConfig).api_url,
                        app_id: (INTERCOM_CONFIG as IntercomConfig).app_id,
                        name: user.first_name,
                        email: user.email,
                        user_id: user.id
                    });
                } else {
                    window.Intercom('boot', {
                        api_base: (INTERCOM_CONFIG as IntercomConfig).api_url,
                        app_id: (INTERCOM_CONFIG as IntercomConfig).app_id
                    });
                }
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom boot error');
                });
            }
        }
    },
    shutdown: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('shutdown');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom shutdown error');
                });
            }
        }
    },
    update: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('update');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom update error');
                });
            }
        }
    },
    hide: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('hide');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom hide error');
                });
            }
        }
    },
    show: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('show');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom show error');
                });
            }
        }
    },
    startTour: (tourId: number) => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('startTour', tourId);
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom show error');
                });
            }
        }
    },
    showSpace: (spaceName: string) => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('showSpace', spaceName);
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom show space error');
                });
            }
        }
    },
    showMessages: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('showMessages');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom show messages error');
                });
            }
        }
    },
    showNewMessage: () => {
        const INTERCOM_CONFIG = getIntercomConfig();
        if ((INTERCOM_CONFIG as IntercomConfig)?.enabled) {
            try {
                window.Intercom('showNewMessage');
            } catch (err) {
                Sentry.withScope((scope: Sentry.Scope) => {
                    scope.setLevel('error');
                    const date = new Date().toISOString();
                    scope.setExtra('###Date', date);
                    scope.setExtra('###error', err);
                    Sentry.captureMessage('Intercom show new messages error');
                });
            }
        }
    }
};
