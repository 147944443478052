import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DsInputErrorsEnum } from './ds-input-errors.models';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ds-input-errors',
    templateUrl: './ds-input-errors.component.html',
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, TranslateModule]
})
export class DsInputErrorsComponent {
    @Input() control: NgControl;
    DsInputErrorsEnum = DsInputErrorsEnum;

    constructor() {}

    /**
     * Get errors object
     *
     */
    get errors() {
        return this.control && this.control.errors;
    }

    /**
     * Get first error type
     *
     */
    get error(): DsInputErrorsEnum {
        return this.errors && (Object.keys(this.errors)[0] as DsInputErrorsEnum);
    }

    /**
     * Control has errors
     *
     */
    get hasErrors() {
        return this.errors && (this.control.touched || this.control.dirty);
    }
}
