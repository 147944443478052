import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

class CustomTranslateLoader extends TranslateHttpLoader implements TranslateLoader {
    constructor(
        http: HttpClient,
        private crowdinKey: string
    ) {
        super(http);
    }

    getTranslation(lang: string) {
        let url: string;
        if (lang === 'en') {
            // URL for English translations
            this.prefix = 'assets/';
        } else {
            // URL for other languages using crowdin
            this.prefix = `https://distributions.crowdin.net/${this.crowdinKey}/content/[Deepersignals.ds-app-split]%20staging/lang/`;
        }
        return super.getTranslation(lang);
    }
}

export function HttpLoaderFactory(http: HttpClient, crowdinKey: string) {
    return new CustomTranslateLoader(http, crowdinKey);
}
