<div>
    <div class="flex" *ngIf="displayLengthCount">
        <label for="{{ name }}" class="block text-sm font-medium text-gray-700">{{ name }}</label>
        <div class="flex-grow flex flex-row-reverse">
            <span class="text-sm text-gray-500">{{ control.value?.length || 0 }}/{{ lengthCount }}</span>
        </div>
    </div>
    <label for="{{ name }}" class="block text-sm font-medium text-gray-700" *ngIf="!displayLengthCount">{{ name }}</label>
    <div
        class="rounded-md shadow-sm"
        [ngClass]="{
            flex: prefix || showButton || suffix,
            relative: !prefix || !suffix,
            'mt-1': name
        }">
        <span *ngIf="prefix" class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {{ prefix }}
        </span>
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20" *ngIf="icon" [innerHtml]="parseIcon(icon)"></div>
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20" *ngIf="!icon && iconName">
            <ds-icon [name]="iconName" [webfontWeight]="DsIconWeightEnum.Light"></ds-icon>
        </div>
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" *ngIf="type === 'search'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>
        <button
            type="button"
            class="-ml-px relative inline-flex items-center px-4 border border-gray-300 text-sm font-medium rounded-l-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
            (click)="buttonOptions.onClick()"
            *ngIf="showButton && buttonOptions && buttonOptions.position === 'start'">
            <ds-icon [name]="buttonOptions.dsIconName" [webfontWeight]="DsIconWeightEnum.Light" *ngIf="buttonOptions.dsIconName"></ds-icon>
            <span *ngIf="buttonOptions.name">{{ buttonOptions.name }}</span>
        </button>
        <input
            [type]="type"
            [id]="id"
            #input
            [autocomplete]="type === 'search' ? 'off' : ''"
            [placeholder]="!focused ? placeholder : ''"
            [disabled]="disabled || _disabled"
            [readonly]="readonly"
            [required]="required"
            [min]="min"
            [max]="max"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (input)="checkOnChange($event.target)"
            class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 focus-within:z-10
        {{ type == 'search' && customClass?.length ? customClass : '' }}"
            (search)="checkOnChange($event.target)"
            [ngClass]="{
                'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': hasErrors,
                'pl-10': iconName || icon || type == 'search',
                'rounded-l-md': (suffix && !prefix) || (showButton && buttonOptions?.position === 'end'),
                'rounded-r-md': prefix || (showButton && buttonOptions?.position === 'start'),
                'flex-1 min-w-0 px-3 py-2': suffix || prefix,
                'rounded-md': !prefix && !showButton && !suffix
            }"
            [class.bg-gray-100]="disabled || _disabled"
            (keydown.enter)="onEnterPressed()" />
        <span *ngIf="suffix" class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-white text-gray-500 sm:text-sm" [ngClass]="{ 'pr-8': type === 'number' && hasErrors }">
            {{ suffix }}
        </span>
        <button
            type="button"
            class="-ml-px relative inline-flex items-center px-4 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
            (click)="buttonOptions.onClick()"
            *ngIf="showButton && buttonOptions && buttonOptions.position === 'end'">
            <ds-icon [name]="buttonOptions.dsIconName" [webfontWeight]="DsIconWeightEnum.Light" *ngIf="buttonOptions.dsIconName"></ds-icon>
            <span *ngIf="buttonOptions.name">{{ buttonOptions.name }}</span>
        </button>
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" *ngIf="(hasErrors || loading) && !showButton">
            <!-- Heroicon name: solid/exclamation-circle -->
            <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20" aria-hidden="true" *ngIf="hasErrors">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
            <svg class="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" *ngIf="loading">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    </div>
    <ds-input-errors [control]="control"></ds-input-errors>
</div>
