export enum LanguageTypeEnum {
    Explicit = 'Explicit',
    AutoDetect = 'AutoDetect'
}

export interface UpdateCurrentLocaleRequest {
    locale: string;
    noSave?: boolean;
}

export interface SetLocaleReq {
    type: LanguageTypeEnum;
    locale?: string;
    sendUpdateLocaleReq?: boolean;
}

export interface LanguageModel {
    code: string;
    name: string;
    locale?: string;
}

export const availableLanguages: LanguageModel[] = [
    { code: 'en', name: 'English' },
    { code: 'it', name: 'Italian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'pt-BR', name: 'Portuguese' },
    { code: 'es-ES', name: 'Spanish' },
    { code: 'de', name: 'German' },
    { code: 'fr', name: 'French' },
    { code: 'es-419', name: 'Spanish, Latam' },
    { code: 'ko', name: 'Korean' },
    { code: 'zh-CN', name: 'Chinese' },
    { code: 'da', name: 'Danish' },
    { code: 'sv-SE', name: 'Swedish' },
    { code: 'id', name: 'Indonesian' },
    { code: 'pl', name: 'Polish' },
    { code: 'sk', name: 'Slovak' },
    { code: 'cs', name: 'Czech' }
];

export const allLanguages: LanguageModel[] = [
    { code: 'ach', locale: 'ach', name: 'Acholi' },
    { code: 'aa', locale: 'aa', name: 'Afar' },
    { code: 'af', locale: 'af', name: 'Afrikaans' },
    { code: 'ak', locale: 'ak', name: 'Akan' },
    { code: 'tw', locale: 'tw', name: 'Akan, Twi' },
    { code: 'sq', locale: 'sq', name: 'Albanian' },
    { code: 'am', locale: 'am', name: 'Amharic' },
    { code: 'ar', locale: 'ar', name: 'Arabic' },
    { code: 'ab', locale: 'ab', name: 'TEST' },
    { code: 'ar-BH', locale: 'ar-BH', name: 'Arabic, Bahrain' },
    { code: 'ar-EG', locale: 'ar-EG', name: 'Arabic, Egypt' },
    { code: 'ar-SA', locale: 'ar-SA', name: 'Arabic, Saudi Arabia' },
    { code: 'ar-YE', locale: 'ar-YE', name: 'Arabic, Yemen' },
    { code: 'an', locale: 'an', name: 'Aragonese' },
    { code: 'hy-AM', locale: 'hy-AM', name: 'Armenian' },
    { code: 'frp', locale: 'frp', name: 'Arpitan' },
    { code: 'as', locale: 'as', name: 'Assamese' },
    { code: 'ast', locale: 'ast', name: 'Asturian' },
    { code: 'tay', locale: 'tay', name: 'Atayal' },
    { code: 'av', locale: 'av', name: 'Avaric' },
    { code: 'ae', locale: 'ae', name: 'Avestan' },
    { code: 'ay', locale: 'ay', name: 'Aymara' },
    { code: 'az', locale: 'az', name: 'Azerbaijani' },
    { code: 'ban', locale: 'ban', name: 'Balinese' },
    { code: 'bal', locale: 'bal', name: 'Balochi' },
    { code: 'bm', locale: 'bm', name: 'Bambara' },
    { code: 'ba', locale: 'ba', name: 'Bashkir' },
    { code: 'eu', locale: 'eu', name: 'Basque' },
    { code: 'be', locale: 'be', name: 'Belarusian' },
    { code: 'bn', locale: 'bn', name: 'Bengali' },
    { code: 'bn-IN', locale: 'bn-IN', name: 'Bengali, India' },
    { code: 'ber', locale: 'ber', name: 'Berber' },
    { code: 'bh', locale: 'bh', name: 'Bihari' },
    { code: 'bfo', locale: 'bfo', name: 'Birifor' },
    { code: 'bi', locale: 'bi', name: 'Bislama' },
    { code: 'bs', locale: 'bs', name: 'Bosnian' },
    { code: 'br-FR', locale: 'br-FR', name: 'Breton' },
    { code: 'bg', locale: 'bg', name: 'Bulgarian' },
    { code: 'my', locale: 'my', name: 'Burmese' },
    { code: 'ca', locale: 'ca', name: 'Catalan' },
    { code: 'ceb', locale: 'ceb', name: 'Cebuano' },
    { code: 'ch', locale: 'ch', name: 'Chamorro' },
    { code: 'ce', locale: 'ce', name: 'Chechen' },
    { code: 'chr', locale: 'chr', name: 'Cherokee' },
    { code: 'ny', locale: 'ny', name: 'Chewa' },
    { code: 'zh-CN', locale: 'zh-CN', name: 'Chinese Simplified' },
    { code: 'zh-TW', locale: 'zh-CN', name: 'Chinese Traditional' },
    { code: 'zh-HK', locale: 'zh-CN', name: 'Chinese Traditional, Hong Kong' },
    { code: 'zh-MO', locale: 'zh-CN', name: 'Chinese Traditional, Macau' },
    { code: 'zh-SG', locale: 'zh-CN', name: 'Chinese Traditional, Singapore' },
    { code: 'cv', locale: 'cv', name: 'Chuvash' },
    { code: 'kw', locale: 'kw', name: 'Cornish' },
    { code: 'co', locale: 'co', name: 'Corsican' },
    { code: 'cr', locale: 'cr', name: 'Cree' },
    { code: 'hr', locale: 'hr', name: 'Croatian' },
    { code: 'cs', locale: 'cs', name: 'Czech' },
    { code: 'da', locale: 'da', name: 'Danish' },
    { code: 'fa-AF', locale: 'fa-AF', name: 'Dari' },
    { code: 'dv', locale: 'dv', name: 'Dhivehi' },
    { code: 'nl', locale: 'nl', name: 'Dutch' },
    { code: 'nl-BE', locale: 'nl-BE', name: 'Dutch, Belgium' },
    { code: 'nl-SR', locale: 'nl-SR', name: 'Dutch, Suriname' },
    { code: 'dz', locale: 'dz', name: 'Dzongkha' },
    { code: 'en', locale: 'en', name: 'English' },
    { code: 'en-UD', locale: 'en', name: 'English (upside down)' },
    { code: 'en-AR', locale: 'en', name: 'English, Arabia' },
    { code: 'en-AU', locale: 'en', name: 'English, Australia' },
    { code: 'en-BZ', locale: 'en', name: 'English, Belize' },
    { code: 'en-CA', locale: 'en', name: 'English, Canada' },
    { code: 'en-CB', locale: 'en', name: 'English, Caribbean' },
    { code: 'en-CN', locale: 'en', name: 'English, China' },
    { code: 'en-DK', locale: 'en', name: 'English, Denmark' },
    { code: 'en-HK', locale: 'en', name: 'English, Hong Kong' },
    { code: 'en-IN', locale: 'en', name: 'English, India' },
    { code: 'en-ID', locale: 'en', name: 'English, Indonesia' },
    { code: 'en-IE', locale: 'en', name: 'English, Ireland' },
    { code: 'en-JM', locale: 'en', name: 'English, Jamaica' },
    { code: 'en-JA', locale: 'en', name: 'English, Japan' },
    { code: 'en-MY', locale: 'en', name: 'English, Malaysia' },
    { code: 'en-NZ', locale: 'en', name: 'English, New Zealand' },
    { code: 'en-NO', locale: 'en', name: 'English, Norway' },
    { code: 'en-PH', locale: 'en', name: 'English, Philippines' },
    { code: 'en-PR', locale: 'en', name: 'English, Puerto Rico' },
    { code: 'en-SG', locale: 'en', name: 'English, Singapore' },
    { code: 'en-ZA', locale: 'en', name: 'English, South Africa' },
    { code: 'en-SE', locale: 'en', name: 'English, Sweden' },
    { code: 'en-GB', locale: 'en', name: 'English, United Kingdom' },
    { code: 'en-US', locale: 'en', name: 'English, United States' },
    { code: 'en-ZW', locale: 'en', name: 'English, Zimbabwe' },
    { code: 'eo', locale: 'eo', name: 'Esperanto' },
    { code: 'et', locale: 'et', name: 'Estonian' },
    { code: 'ee', locale: 'ee', name: 'Ewe' },
    { code: 'fo', locale: 'fo', name: 'Faroese' },
    { code: 'fj', locale: 'fj', name: 'Fijian' },
    { code: 'fil', locale: 'fil', name: 'Filipino' },
    { code: 'fi', locale: 'fi', name: 'Finnish' },
    { code: 'vls-BE', locale: 'vls-BE', name: 'Flemish' },
    { code: 'fra-DE', locale: 'fra-DE', name: 'Franconian' },
    { code: 'fr', locale: 'fr', name: 'French' },
    { code: 'fr-BE', locale: 'fr', name: 'French, Belgium' },
    { code: 'fr-CA', locale: 'fr', name: 'French, Canada' },
    { code: 'fr-LU', locale: 'fr', name: 'French, Luxembourg' },
    { code: 'fr-QC', locale: 'fr', name: 'French, Quebec' },
    { code: 'fr-CH', locale: 'fr', name: 'French, Switzerland' },
    { code: 'fy-NL', locale: 'fy-NL', name: 'Frisian' },
    { code: 'fur-IT', locale: 'fur-IT', name: 'Friulian' },
    { code: 'ff', locale: 'ff', name: 'Fula' },
    { code: 'gaa', locale: 'gaa', name: 'Ga' },
    { code: 'gl', locale: 'gl', name: 'Galician' },
    { code: 'ka', locale: 'ka', name: 'Georgian' },
    { code: 'de', locale: 'de', name: 'German' },
    { code: 'de-AT', locale: 'de', name: 'German, Austria' },
    { code: 'de-BE', locale: 'de', name: 'German, Belgium' },
    { code: 'de-LI', locale: 'de', name: 'German, Liechtenstein' },
    { code: 'de-LU', locale: 'de', name: 'German, Luxembourg' },
    { code: 'de-CH', locale: 'de', name: 'German, Switzerland' },
    { code: 'got', locale: 'got', name: 'Gothic' },
    { code: 'el', locale: 'el', name: 'Greek' },
    { code: 'el-CY', locale: 'el-CY', name: 'Greek, Cyprus' },
    { code: 'kl', locale: 'kl', name: 'Greenlandic' },
    { code: 'gn', locale: 'gn', name: 'Guarani' },
    { code: 'gu-IN', locale: 'gu-IN', name: 'Gujarati' },
    { code: 'ht', locale: 'ht', name: 'Haitian Creole' },
    { code: 'ha', locale: 'ha', name: 'Hausa' },
    { code: 'haw', locale: 'haw', name: 'Hawaiian' },
    { code: 'he', locale: 'he', name: 'Hebrew' },
    { code: 'hz', locale: 'hz', name: 'Herero' },
    { code: 'hil', locale: 'hil', name: 'Hiligaynon' },
    { code: 'hi', locale: 'hi', name: 'Hindi' },
    { code: 'ho', locale: 'ho', name: 'Hiri Motu' },
    { code: 'hmn', locale: 'hmn', name: 'Hmong' },
    { code: 'hu', locale: 'hu', name: 'Hungarian' },
    { code: 'is', locale: 'is', name: 'Icelandic' },
    { code: 'ido', locale: 'ido', name: 'Ido' },
    { code: 'ig', locale: 'ig', name: 'Igbo' },
    { code: 'ilo', locale: 'ilo', name: 'Ilokano' },
    { code: 'id', locale: 'id', name: 'Indonesian' },
    { code: 'iu', locale: 'iu', name: 'Inuktitut' },
    { code: 'ga-IE', locale: 'ga-IE', name: 'Irish' },
    { code: 'it', locale: 'it', name: 'Italian' },
    { code: 'it-CH', locale: 'it', name: 'Italian, Switzerland' },
    { code: 'ja', locale: 'ja', name: 'Japanese' },
    { code: 'jv', locale: 'jv', name: 'Javanese' },
    { code: 'quc', locale: 'quc', name: "K'iche'" },
    { code: 'kab', locale: 'kab', name: 'Kabyle' },
    { code: 'kn', locale: 'kn', name: 'Kannada' },
    { code: 'pam', locale: 'pam', name: 'Kapampangan' },
    { code: 'ks', locale: 'ks', name: 'Kashmiri' },
    { code: 'ks-PK', locale: 'ks-PK', name: 'Kashmiri, Pakistan' },
    { code: 'csb', locale: 'csb', name: 'Kashubian' },
    { code: 'kk', locale: 'kk', name: 'Kazakh' },
    { code: 'km', locale: 'km', name: 'Khmer' },
    { code: 'rw', locale: 'rw', name: 'Kinyarwanda' },
    { code: 'tlh-AA', locale: 'tlh-AA', name: 'Klingon' },
    { code: 'kv', locale: 'kv', name: 'Komi' },
    { code: 'kg', locale: 'kg', name: 'Kongo' },
    { code: 'kok', locale: 'kok', name: 'Konkani' },
    { code: 'ko', locale: 'ko', name: 'Korean' },
    { code: 'ku', locale: 'ku', name: 'Kurdish' },
    { code: 'kmr', locale: 'kmr', name: 'Kurmanji (Kurdish)' },
    { code: 'kj', locale: 'kj', name: 'Kwanyama' },
    { code: 'ky', locale: 'ky', name: 'Kyrgyz' },
    { code: 'lol', locale: 'lol', name: 'LOLCAT' },
    { code: 'lo', locale: 'lo', name: 'Lao' },
    { code: 'la-LA', locale: 'la-LA', name: 'Latin' },
    { code: 'lv', locale: 'lv', name: 'Latvian' },
    { code: 'lij', locale: 'lij', name: 'Ligurian' },
    { code: 'li', locale: 'li', name: 'Limburgish' },
    { code: 'ln', locale: 'ln', name: 'Lingala' },
    { code: 'lt', locale: 'lt', name: 'Lithuanian' },
    { code: 'jbo', locale: 'jbo', name: 'Lojban' },
    { code: 'nds', locale: 'nds', name: 'Low German' },
    { code: 'dsb-DE', locale: 'dsb-DE', name: 'Lower Sorbian' },
    { code: 'lg', locale: 'lg', name: 'Luganda' },
    { code: 'luy', locale: 'luy', name: 'Luhya' },
    { code: 'lb', locale: 'lb', name: 'Luxembourgish' },
    { code: 'mk', locale: 'mk', name: 'Macedonian' },
    { code: 'mai', locale: 'mai', name: 'Maithili' },
    { code: 'mg', locale: 'mg', name: 'Malagasy' },
    { code: 'ms', locale: 'ms', name: 'Malay' },
    { code: 'ms-BN', locale: 'ms-BN', name: 'Malay, Brunei' },
    { code: 'ml-IN', locale: 'ml-IN', name: 'Malayalam' },
    { code: 'mt', locale: 'mt', name: 'Maltese' },
    { code: 'gv', locale: 'gv', name: 'Manx' },
    { code: 'mi', locale: 'mi', name: 'Maori' },
    { code: 'arn', locale: 'arn', name: 'Mapudungun' },
    { code: 'mr', locale: 'mr', name: 'Marathi' },
    { code: 'mh', locale: 'mh', name: 'Marshallese' },
    { code: 'moh', locale: 'moh', name: 'Mohawk' },
    { code: 'mn', locale: 'mn', name: 'Mongolian' },
    {
        code: 'sr-Cyrl-ME',
        locale: 'sr-Cyrl-ME',
        name: 'Montenegrin (Cyrillic)'
    },
    { code: 'me', locale: 'me', name: 'Montenegrin (Latin)' },
    { code: 'mos', locale: 'mos', name: 'Mossi' },
    { code: 'na', locale: 'na', name: 'Nauru' },
    { code: 'ng', locale: 'ng', name: 'Ndonga' },
    { code: 'ne-NP', locale: 'ne-NP', name: 'Nepali' },
    { code: 'ne-IN', locale: 'ne-IN', name: 'Nepali, India' },
    { code: 'pcm', locale: 'pcm', name: 'Nigerian Pidgin' },
    { code: 'se', locale: 'se', name: 'Northern Sami' },
    { code: 'nso', locale: 'nso', name: 'Northern Sotho' },
    { code: 'no', locale: 'no', name: 'Norwegian' },
    { code: 'nb', locale: 'nb', name: 'Norwegian Bokmal' },
    { code: 'nn-NO', locale: 'nn-NO', name: 'Norwegian Nynorsk' },
    { code: 'oc', locale: 'oc', name: 'Occitan' },
    { code: 'or', locale: 'or', name: 'Odia' },
    { code: 'oj', locale: 'oj', name: 'Ojibwe' },
    { code: 'om', locale: 'om', name: 'Oromo' },
    { code: 'os', locale: 'os', name: 'Ossetian' },
    { code: 'pi', locale: 'pi', name: 'Pali' },
    { code: 'pap', locale: 'pap', name: 'Papiamento' },
    { code: 'ps', locale: 'ps', name: 'Pashto' },
    { code: 'fa', locale: 'fa', name: 'Persian' },
    { code: 'en-PT', locale: 'en', name: 'Pirate English' },
    { code: 'pl', locale: 'pl', name: 'Polish' },
    { code: 'pt-PT', locale: 'pt-BR', name: 'Portuguese' },
    { code: 'pt-BR', locale: 'pt-BR', name: 'Portuguese, Brazilian' },
    { code: 'pa-IN', locale: 'pa-IN', name: 'Punjabi' },
    { code: 'pa-PK', locale: 'pa-PK', name: 'Punjabi, Pakistan' },
    { code: 'qu', locale: 'qu', name: 'Quechua' },
    { code: 'qya-AA', locale: 'qya-AA', name: 'Quenya' },
    { code: 'ro', locale: 'ro', name: 'Romanian' },
    { code: 'rm-CH', locale: 'rm-CH', name: 'Romansh' },
    { code: 'rn', locale: 'rn', name: 'Rundi' },
    { code: 'ru', locale: 'ru', name: 'Russian' },
    { code: 'ru-BY', locale: 'ru-BY', name: 'Russian, Belarus' },
    { code: 'ru-MD', locale: 'ru-MD', name: 'Russian, Moldova' },
    { code: 'ru-UA', locale: 'ru-UA', name: 'Russian, Ukraine' },
    { code: 'ry-UA', locale: 'ry-UA', name: 'Rusyn' },
    { code: 'sah', locale: 'sah', name: 'Sakha' },
    { code: 'sg', locale: 'sg', name: 'Sango' },
    { code: 'sa', locale: 'sa', name: 'Sanskrit' },
    { code: 'sat', locale: 'sat', name: 'Santali' },
    { code: 'sc', locale: 'sc', name: 'Sardinian' },
    { code: 'sco', locale: 'sco', name: 'Scots' },
    { code: 'gd', locale: 'gd', name: 'Scottish Gaelic' },
    { code: 'sr', locale: 'sr', name: 'Serbian (Cyrillic)' },
    { code: 'sr-CS', locale: 'sr-CS', name: 'Serbian (Latin)' },
    { code: 'sh', locale: 'sh', name: 'Serbo-Croatian' },
    { code: 'crs', locale: 'crs', name: 'Seychellois Creole' },
    { code: 'sn', locale: 'sn', name: 'Shona' },
    { code: 'ii', locale: 'ii', name: 'Sichuan Yi' },
    { code: 'sd', locale: 'sd', name: 'Sindhi' },
    { code: 'si-LK', locale: 'si-LK', name: 'Sinhala' },
    { code: 'sk', locale: 'sk', name: 'Slovak' },
    { code: 'sl', locale: 'sl', name: 'Slovenian' },
    { code: 'so', locale: 'so', name: 'Somali' },
    { code: 'son', locale: 'son', name: 'Songhay' },
    { code: 'ckb', locale: 'ckb', name: 'Sorani (Kurdish)' },
    { code: 'nr', locale: 'nr', name: 'Southern Ndebele' },
    { code: 'sma', locale: 'sma', name: 'Southern Sami' },
    { code: 'st', locale: 'st', name: 'Southern Sotho' },
    { code: 'es-ES', locale: 'es-ES', name: 'Spanish' },
    { code: 'es-EM', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-AR', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-BO', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-CL', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-CO', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-CR', locale: 'es-419', name: 'Spanish, Costa Rica' },
    { code: 'es-DO', locale: 'es-419', name: 'Spanish, Dominican Republic' },
    { code: 'es-EC', locale: 'es-419', name: 'Spanish, Ecuador' },
    { code: 'es-SV', locale: 'es-419', name: 'Spanish, El Salvador' },
    { code: 'es-GT', locale: 'es-419', name: 'Spanish, Guatemala' },
    { code: 'es-HN', locale: 'es-419', name: 'Spanish, Honduras' },
    { code: 'es-419', locale: 'es-419', name: 'Spanish, Latin America' },
    { code: 'es-MX', locale: 'es-419', name: 'Spanish, Mexico' },
    { code: 'es-NI', locale: 'es-419', name: 'Spanish, Nicaragua' },
    { code: 'es-PA', locale: 'es-419', name: 'Spanish, Panama' },
    { code: 'es-PY', locale: 'es-419', name: 'Spanish, Paraguay' },
    { code: 'es-PE', locale: 'es-419', name: 'Spanish, Peru' },
    { code: 'es-PR', locale: 'es-419', name: 'Spanish, Puerto Rico' },
    { code: 'es-US', locale: 'es-419', name: 'Spanish, United States' },
    { code: 'es-UY', locale: 'es-419', name: 'Spanish, Uruguay' },
    { code: 'es-VE', locale: 'es-419', name: 'Spanish, Venezuela' },
    { code: 'su', locale: 'su', name: 'Sundanese' },
    { code: 'sw', locale: 'sw', name: 'Swahili' },
    { code: 'sw-KE', locale: 'sw-KE', name: 'Swahili, Kenya' },
    { code: 'sw-TZ', locale: 'sw-TZ', name: 'Swahili, Tanzania' },
    { code: 'ss', locale: 'ss', name: 'Swati' },
    { code: 'sv-SE', locale: 'sv-SE', name: 'Swedish' },
    { code: 'sv-FI', locale: 'sv-FI', name: 'Swedish, Finland' },
    { code: 'syc', locale: 'syc', name: 'Syriac' },
    { code: 'tl', locale: 'tl', name: 'Tagalog' },
    { code: 'ty', locale: 'ty', name: 'Tahitian' },
    { code: 'tg', locale: 'tg', name: 'Tajik' },
    { code: 'tzl', locale: 'tzl', name: 'Talossan' },
    { code: 'ta', locale: 'ta', name: 'Tamil' },
    { code: 'tt-RU', locale: 'tt-RU', name: 'Tatar' },
    { code: 'te', locale: 'te', name: 'Telugu' },
    { code: 'kdh', locale: 'kdh', name: 'Tem (Kotokoli)' },
    { code: 'th', locale: 'th', name: 'Thai' },
    { code: 'bo-BT', locale: 'bo-BT', name: 'Tibetan' },
    { code: 'ti', locale: 'ti', name: 'Tigrinya' },
    { code: 'ts', locale: 'ts', name: 'Tsonga' },
    { code: 'tn', locale: 'tn', name: 'Tswana' },
    { code: 'tr', locale: 'tr', name: 'Turkish' },
    { code: 'tr-CY', locale: 'tr-CY', name: 'Turkish, Cyprus' },
    { code: 'tk', locale: 'tk', name: 'Turkmen' },
    { code: 'uk', locale: 'uk', name: 'Ukrainian' },
    { code: 'hsb-DE', locale: 'hsb-DE', name: 'Upper Sorbian' },
    { code: 'ur-IN', locale: 'ur-IN', name: 'Urdu (India)' },
    { code: 'ur-PK', locale: 'ur-PK', name: 'Urdu (Pakistan)' },
    { code: 'ug', locale: 'ug', name: 'Uyghur' },
    { code: 'uz', locale: 'uz', name: 'Uzbek' },
    { code: 'val-ES', locale: 'val-ES', name: 'Valencian' },
    { code: 've', locale: 've', name: 'Venda' },
    { code: 'vec', locale: 'vec', name: 'Venetian' },
    { code: 'vi', locale: 'vi', name: 'Vietnamese' },
    { code: 'wa', locale: 'wa', name: 'Walloon' },
    { code: 'cy', locale: 'cy', name: 'Welsh' },
    { code: 'wo', locale: 'wo', name: 'Wolof' },
    { code: 'xh', locale: 'xh', name: 'Xhosa' },
    { code: 'yi', locale: 'yi', name: 'Yiddish' },
    { code: 'yo', locale: 'yo', name: 'Yoruba' },
    { code: 'zea', locale: 'zea', name: 'Zeelandic' },
    { code: 'zu', locale: 'zu', name: 'Zulu' }
];

export function convertLanguageStringsToLanguageModel(languages: string[]): LanguageModel[] {
    return languages.map((x) => allLanguages.find((al) => al.code === x));
}
