import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/*
 *   For takeUntil unsubscribe strategy, although this could grow depending on shared component requirements
 */
@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    destroy$ = new Subject<void>();

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
