export enum DsInputErrorsEnum {
    Required = 'required',
    RequiredTrue = 'requiredTrue',
    MinLength = 'minlength',
    MaxLength = 'maxlength',
    Min = 'min',
    Max = 'max',
    Pattern = 'pattern',
    ArrayValueRequired = 'arrayValueRequired',
    Email = 'email',
    PasswordPattern = 'passwordPattern',
    Same = 'same',
    Whitespace = 'whitespace',
    NotSame = 'notSame',
    DatePattern = 'datePattern',
    InvalidDomain = 'invalidDomain',
    SpecialCharacter = 'specialCharacterError'
}
