import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CORE_LIB_CONFIG, CoreLibConfig } from 'core-lib';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageSwitcherService {
    constructor(
        private http: HttpClient,
        @Inject(CORE_LIB_CONFIG) private config: CoreLibConfig
    ) {}

    updateLocale(locale: string): Observable<any> {
        return this.http.put(`${this.config.apiUrl}user/locale/${locale}`, null);
    }
}
