import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DsModalCloseObject } from './ds-modal.model';

@Injectable({
    providedIn: 'root'
})
export class DsModalService {
    private modalClosedListener$$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    modalClosedListener$: Observable<string> = this.modalClosedListener$$.asObservable();

    private openModal$$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    openModal$: Observable<string> = this.openModal$$.asObservable();
    private closeModal$$: BehaviorSubject<DsModalCloseObject> = new BehaviorSubject<DsModalCloseObject>(undefined);
    closeModal$: Observable<DsModalCloseObject> = this.closeModal$$.asObservable();

    private modalIds: string[] = [];

    //use ModalEnum as input but to not break anything leaving string for now
    open(id: string) {
        this.openModal$$.next(id);
        if (this.modalIds.indexOf(id) === -1) {
            this.modalIds.push(id);
        }
    }

    close(id: string, result?: any) {
        let closeResult: DsModalCloseObject = {
            id: id,
            result: result
        };
        this.closeModal$$.next(closeResult);
        this.updateModalClosedListener(id);
        this.modalIds = this.modalIds.filter((i) => i !== id);
    }

    closeAll(): void {
        this.modalIds.forEach((id) => {
            this.close(id);
        });
    }

    //DEPRECATE IN FUTURE
    updateModalClosedListener(modalId: string): void {
        this.modalClosedListener$$.next(modalId);
    }

    destroyAll(): void {
        this.openModal$$.next(undefined);
        this.closeModal$$.next(undefined);
        this.modalClosedListener$$.next(undefined);
    }
}
