import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CryptSecretKeysEnum, UserRolesEnum } from '../models';
import { SavedUser, SavedUserTokens, UserV3 } from '../models/users.models';
import * as CryptoJS from 'crypto-js';

export const LS_SAVED_USER_STRING = 'ds-saved-logins';

@Injectable({
    providedIn: 'root'
})
export class SavedUsersService {
    set users(v: SavedUser[]) {
        let cripted = CryptoJS.AES.encrypt(JSON.stringify(v), CryptSecretKeysEnum.SavedUsers).toString();
        localStorage.setItem(LS_SAVED_USER_STRING, cripted);
    }
    get users(): SavedUser[] {
        /** Checks if there is a saved array of user logins in LS */
        const lsValue: string = localStorage.getItem(LS_SAVED_USER_STRING);
        let isEncripted = this.isSavedUsersEncripted(lsValue);
        if (!isEncripted) this.users = JSON.parse(lsValue);

        return this.returnEncriptedUsers(lsValue);
    }

    constructor() {
        this.convertSavedUsersFromLocalStorage();
    }

    private returnEncriptedUsers(value: string): SavedUser[] {
        try {
            const bytes = CryptoJS.AES.decrypt(value, CryptSecretKeysEnum.SavedUsers);
            const bytesString = bytes.toString(CryptoJS.enc.Utf8);
            if (bytesString !== 'null') return JSON.parse(bytesString);

            return [];
        } catch {
            return [];
        }
    }

    private isSavedUsersEncripted(users: string): boolean {
        try {
            JSON.parse(users);
            return false;
        } catch {
            return true;
        }
    }

    /** Goes through the array and returns true if that user is already saved */
    checkIfUserExists(userId: number): boolean {
        const _users: SavedUser[] = this.users;
        if (!_users.length) return false;

        const userExists = _users.filter((mappedUser) => mappedUser.user?.id === userId).length;
        return userExists > 0;
    }

    isSavedUserAdmin(userId: number): boolean {
        const _users: SavedUser[] = this.users;
        const user: SavedUser = _users.filter((mappedUser) => mappedUser.user?.id === userId)[0];
        return user.user?.roles.includes(UserRolesEnum.Admin) ? true : false;
    }

    getUsersByAccountIndexName(accountIndexName: string): SavedUser[] {
        if (!!accountIndexName) return this.users.filter((u) => u.user?.account?.index_name === accountIndexName);

        return this.users;
    }

    getUsersByAccountIndexNameAndOrderByLastLogin(accountIndexName: string): SavedUser[] {
        let users = this.users;
        if (!!accountIndexName) users = this.users.filter((u) => u.user?.account?.index_name === accountIndexName);

        const withLastLogin = users.filter((user) => !!user.lastLogin);
        const withoutLastLogin = users.filter((user) => !user.lastLogin);

        withLastLogin.sort((a, b) => b.lastLogin - a.lastLogin);

        return [...withLastLogin, ...withoutLastLogin];
    }

    updateUserLastLogin(userId: number, lastLogin: Date): void {
        const _users: SavedUser[] = _.cloneDeep(this.users);
        const index: number = _users.findIndex((u) => u.user?.id === userId);
        if (index !== -1) {
            _users[index].lastLogin = lastLogin.getTime();
            this.users = _users;
        }
    }

    updateUser(user: UserV3): void {
        const _users: SavedUser[] = this.users;
        const index: number = _users.findIndex((u) => u.user?.id === user?.id);
        try {
            if (index > -1) {
                _users[index].user = user;
                this.users = _users;
            } else console.log('User found', user);
        } catch (error) {
            console.log(error);
        }
    }

    updateUserLocale(locale: string, userId: number): void {
        const _users: SavedUser[] = this.users;
        const index: number = _users.findIndex((u) => u.user?.id === userId);
        try {
            if (index !== -1) {
                _users[index].user['locale'] = locale;
                this.users = _users;
            }
        } catch (error) {
            console.log(error);
        }
    }

    updateUserTokens(userId: number, savedUserTokens?: SavedUserTokens): void {
        try {
            const _users = this.users;
            const index: number = _users.findIndex((u) => u.user?.id === userId);
            if (index > -1) {
                Object.keys(savedUserTokens).forEach((key) => {
                    _users[index].tokens[key] = savedUserTokens[key];
                });
                this.users = _users;
            }
        } catch (error) {
            console.log(error);
        }
    }

    updateEmailToSavedUserList(oldEmail: string, newEmail: string, indexName: string) {
        const _users = this.users;
        if (!_users.length) return;

        const index: number = _users.findIndex((u) => u.user?.account?.index_name === indexName && u.user?.email === oldEmail);

        if (index > -1) {
            _users[index].user.email = newEmail;
        }

        this.users = _users;
    }

    addNewEntry(user: SavedUser): void {
        this.users = [...this.users, user];
    }

    removeEntryById(userId: number): void {
        let _users = this.users;

        if (!_users.length) return;

        _users = _users.filter((u) => !(userId === u.user?.id));

        this.users = _users;
    }

    removeEntryByAccountId(accountId: number): void {
        let _users = this.users;

        if (!_users.length) return;

        _users = _users.filter((u) => !(accountId === u.user?.account?.id));

        this.users = _users;
    }

    /*
     * This is to convert clients local storage to work with new ls object/interface
     */
    convertSavedUsersFromLocalStorage(): void {
        let newUsers: SavedUser[];
        if (this.users?.length > 0 && ((this.users[0] as any).auth || (this.users[0] as any).account_index)) {
            newUsers = this.users.map((user: any) => {
                if (!!user.auth) {
                    return {
                        user: user.auth?.user as UserV3,
                        tokens: {
                            access_token: user.auth?.user?.token_key,
                            refresh_token: user.auth?.user?.refresh_token,
                            quiz_token: user.auth?.quizToken
                        }
                    };
                } else {
                    return undefined;
                }
            });

            this.users = newUsers.filter((x) => !!x);
        }
    }
}
