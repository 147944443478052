import { BehaviorSubject, Observable, catchError, filter, finalize, switchMap, take, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CORE_LIB_CONFIG, CoreLibConfig, CoreSessionStorageService, LoginV3Response, SavedUsersService } from 'core-lib';
import { TokenService } from '../services/token.service';
import { RefreshTokenReq } from '../models/token.models';

//@todo-m todo-j check error handling
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private sessionStorageService: CoreSessionStorageService,
        private tokenService: TokenService,
        private savedUsersService: SavedUsersService,
        @Inject(CORE_LIB_CONFIG) private config: CoreLibConfig
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /*
         *   Inject token on every request
         */
        request = this.setRequestToken(request);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error?.status === 401) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            const refreshToken = _.cloneDeep(this.sessionStorageService.getRefreshToken());
            if (!refreshToken) {
                this.logout();
                return throwError(() => new Error('No refresh token available'));
            }
            const refreshTokenReq: RefreshTokenReq = {
                user_id: this.sessionStorageService.getUserId(),
                refresh_token: refreshToken
            };
            return this.tokenService.refreshTokenReq(refreshTokenReq).pipe(
                switchMap((response: LoginV3Response) => {
                    this.sessionStorageService.setUser(response.user);
                    this.sessionStorageService.setUserTokens(response.token_key, response.refresh_token, response.mercure_token);
                    this.refreshTokenSubject.next(true);
                    return next.handle(this.setRequestToken(request));
                }),
                catchError((err) => {
                    this.refreshTokenSubject.next(false);
                    this.logout();
                    return throwError(() => err);
                }),
                finalize(() => {
                    this.refreshTokenInProgress = false;
                })
            );
        } else {
            this.refreshTokenSubject.next(false);
            return this.refreshTokenSubject.pipe(
                filter((success) => success),
                take(1),
                switchMap(() => next.handle(this.setRequestToken(request)))
            );
        }
    }

    private setRequestToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = _.cloneDeep(this.sessionStorageService.getAccessToken());
        if (request.url.includes('api')) {
            if (request.url.startsWith(this.config.apiUrl)) {
                if (!!token) {
                    return request.clone({
                        setHeaders: {
                            'X-TOKEN': `${token}`
                        }
                    });
                }
            } else if (request.url.startsWith(this.config.quizApiUrl)) {
                if (request.url.includes('results')) {
                    const token = this.sessionStorageService.getQuizToken();
                    if (!!token) {
                        return request.clone({
                            setHeaders: {
                                'q-token': `${token}`
                            }
                        });
                    }
                } else {
                    return request.clone({
                        setHeaders: {
                            'X-TOKEN': `${token}`
                        }
                    });
                }
            }
        }
        return request;
    }

    private logout(): void {
        this.savedUsersService.updateUserTokens(this.sessionStorageService.getUserId(), {
            access_token: null,
            refresh_token: null,
            quiz_token: null
        });
        this.sessionStorageService.removeUserTokens();
        window.location.href = '/auth/logout';
    }
}
