import { Data, Route } from '@angular/router';
import { AsideIdentifierEnum, AsideItemIdentifierEnum, HeaderIdentifierEnum, LayoutEnum, SidenavItemIdentifierEnum } from './ui.models';
import { PermissionsEnum } from 'projects/admin/src/common/models/permissions.model';
export interface GeneralResponse {
    code: number;
    message: string;
}

export interface DownloadItemRes {
    download_url?: string;
    message?: string;
    file_name: string;
}

export enum UsersTableContext {
    AllUsers = 0,
    ProjectUsers = 1,
    AddUsersToProject = 2
}

export interface PaginationQueryParams {
    _page: number;
    /** Number of users per page */
    _size: number;
    /** Sort field id */
    _sort?: string;
    _order?: any;
    _filter?:
        | {
              groups?: string[];
              roles?: string[];
              not_in_project?: number;
              teams?: string[] | number[];
              team_role?: string[];
              projects?: string[];
              show_anon?: boolean;
              show_inactive?: boolean;
              show_dummy?: boolean;
              user_created_range?: { from: string; to: string };
          }
        | string;
    _q?: string;
    account?: string;
    anon?: boolean;
    projectId?: string | number;
    context?: UsersTableContext;
}

export interface ApiQueryParams {
    _sort?: string;
    _order?: 'ASC' | 'DESC';
    _q?: string;
    _page?: number;
    _size?: number;
}

export const DEFAULT_API_QUERY_PARAMS = {
    _sort: 'name',
    _order: 'ASC',
    _page: 1,
    _size: 10
} as ApiQueryParams;

export enum CookieIdsEnum {
    SessionTracking = 'SessionTracking',
    SessionUserID = 'sessionUserID'
}

export interface DsRouteData extends Data {
    layout?: LayoutEnum;
    asideIdentifier?: AsideIdentifierEnum;
    asideItemIdentifier?: AsideItemIdentifierEnum;
    sidenavItemIdentifier?: SidenavItemIdentifierEnum;
    headerIdentifier?: HeaderIdentifierEnum;
    permissions?: PermissionsEnum[];
}

export interface DsRoute extends Route {
    data?: DsRouteData;
}
export type DsRoutes = DsRoute[];

export interface IdNameModel {
    id: number;
    name: string;
}
export interface GeneralIdNameModel<T> {
    id: T;
    name: string;
}

export interface DefaultSelectOptionsModel {
    value: string;
    name: string;
}

export interface MessageResponse {
    message: string;
}
