import { Component, ElementRef, Input, OnDestroy, OnInit, WritableSignal, signal } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';

import { DsSpinnerType } from './ds-spinner.models';
import { DsSpinnerService } from './ds-spinner.service';
import { BaseComponent } from '../../base.component';
import { filter, takeUntil } from 'rxjs';

@Component({
    selector: 'ds-spinner',
    templateUrl: './ds-spinner.component.html',
    styleUrls: ['./ds-spinner.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class DsSpinnerComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() mode: DsSpinnerType = DsSpinnerType.Spinner;
    @Input() text: string = '';

    @Input() appendToBody: boolean = false;
    DsSpinnerType = DsSpinnerType;
    isVisible: boolean;

    loadingMessage: WritableSignal<string> = signal('test');
    constructor(
        private el: ElementRef,
        private spinnerService: DsSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.appendToBody) {
            document.body.appendChild(this.el.nativeElement);
        }

        if (this.mode === DsSpinnerType.Spinner) {
            this.spinnerService.spinnerState$
                .pipe(
                    filter((x) => !!x),
                    takeUntil(this.destroy$)
                )
                .subscribe((value) => {
                    this.isVisible = value.isVisible;
                    this.text = value.text;
                });
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.appendToBody) {
            document.body.removeChild(this.el.nativeElement);
        }
    }
}
