import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, OnInitEffects } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { LanguageSwitcherActions } from '../actions';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTypeEnum, SessionStorageKeysEnum, allLanguages } from '../models';
import { DEFAULT_LANGUAGE } from '../../core-lib.config';
import { CoreSessionStorageService, LanguageSwitcherReducers, SavedUsersService } from 'core-lib';
import { Action, Store } from '@ngrx/store';
import { LanguageSwitcherService } from '../services/language-switcher.service';

@Injectable()
export class LanguageSwitcherEffects implements OnInitEffects {
    constructor(
        private actions$: Actions,
        private translateService: TranslateService,
        private sessionStorageService: CoreSessionStorageService,
        private store: Store,
        private languageSwitcherService: LanguageSwitcherService,
        private savedUsersService: SavedUsersService
    ) {}

    ngrxOnInitEffects(): Action {
        return LanguageSwitcherActions.updateCurrentLanguage({ payload: { locale: this.sessionStorageService.getLocale() ?? DEFAULT_LANGUAGE } });
    }

    updateCurrentLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LanguageSwitcherActions.updateCurrentLanguage),
            map((action) => action.payload),
            withLatestFrom(this.store.select(LanguageSwitcherReducers.selectAvailableLanguages)),
            exhaustMap((result) => {
                const request = result[0];
                const availableLanguages = result[1] ?? [];
                let availableLanguage = availableLanguages.find((x) => x.code === request.locale);
                if (!!availableLanguage) {
                    if (!request.noSave) {
                        this.sessionStorageService.setLocale(availableLanguage.code);
                    }
                    document.querySelector('html')?.setAttribute('lang', availableLanguage.code);
                    return this.translateService.use(availableLanguage.code).pipe(
                        map((response) => LanguageSwitcherActions.updateCurrentLanguageSuccess({ payload: availableLanguage.code })),
                        catchError((error) => of(LanguageSwitcherActions.updateCurrentLanguageFailure({ error })))
                    );
                } else {
                    if (!request.noSave) {
                        this.sessionStorageService.setLocale(DEFAULT_LANGUAGE);
                    }
                    document.querySelector('html')?.setAttribute('lang', DEFAULT_LANGUAGE);
                    return this.translateService.use(DEFAULT_LANGUAGE).pipe(
                        map((response) => LanguageSwitcherActions.updateCurrentLanguageSuccess({ payload: DEFAULT_LANGUAGE })),
                        catchError((error) => of(LanguageSwitcherActions.updateCurrentLanguageFailure({ error })))
                    );
                }
            })
        )
    );

    updateAvailableLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LanguageSwitcherActions.updateAvailableLanguages),
            map((action) => action.payload),
            exhaustMap((langs) =>
                of(langs).pipe(
                    map((response) => LanguageSwitcherActions.updateAvailableLanguagesSuccess({ payload: response })),
                    catchError((error) => of(LanguageSwitcherActions.updateAvailableLanguagesFailure({ error })))
                )
            )
        )
    );

    updateUserLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LanguageSwitcherActions.updateUserLocale),
            map((action) => action.payload),
            exhaustMap((locale) =>
                this.languageSwitcherService.updateLocale(locale).pipe(
                    map((response) => {
                        this.savedUsersService.updateUserLocale(locale, this.sessionStorageService.getUserId());
                        return LanguageSwitcherActions.updateUserLocaleSuccess();
                    }),
                    catchError((error) => of(LanguageSwitcherActions.updateUserLocaleFailure({ error })))
                )
            )
        )
    );

    setLocale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LanguageSwitcherActions.setLocale),
            map((action) => action.payload),
            exhaustMap((setLocaleReq) => {
                const sessionLanguage = sessionStorage.getItem(SessionStorageKeysEnum.Locale);
                switch (setLocaleReq.type) {
                    case LanguageTypeEnum.Explicit:
                        return this.setTranslateLanguage(setLocaleReq.locale, setLocaleReq.sendUpdateLocaleReq, false).pipe(map(() => LanguageSwitcherActions.setLocaleSuccess()));
                    case LanguageTypeEnum.AutoDetect:
                        let navigatorLocale = navigator.language;
                        return this.setTranslateLanguage(navigatorLocale, setLocaleReq.sendUpdateLocaleReq, true).pipe(map(() => LanguageSwitcherActions.setLocaleSuccess()));
                    default:
                        if (sessionLanguage) {
                            return this.setTranslateLanguage(sessionLanguage, setLocaleReq.sendUpdateLocaleReq, false).pipe(map(() => LanguageSwitcherActions.setLocaleSuccess()));
                        } else {
                            let navigatorLocale = navigator.language;
                            return this.setTranslateLanguage(navigatorLocale, setLocaleReq.sendUpdateLocaleReq, true).pipe(map(() => LanguageSwitcherActions.setLocaleSuccess()));
                        }
                }
            })
        )
    );

    //#region Private Methods

    private setTranslateLanguage(locale: string, updateSendUpdateLocaleReq: boolean, isAutoDetected: boolean): Observable<boolean> {
        if (isAutoDetected) {
            let crowdinLanguage = allLanguages.find((x) => x.code === locale || x.code === locale.substring(0, 2));
            if (crowdinLanguage) {
                locale = crowdinLanguage.code;
            }
        }
        this.store.dispatch(LanguageSwitcherActions.updateCurrentLanguage({ payload: { locale } }));
        if (updateSendUpdateLocaleReq) {
            this.store.dispatch(LanguageSwitcherActions.updateUserLocale({ payload: locale }));
        }

        return of(true);
    }

    //#endregion
}
