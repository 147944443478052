import * as FileSaver from 'file-saver';
import { PaginationQueryParams, ApiQueryParams } from '../models/generics.models';
import { Md5 } from 'ts-md5';
import { UserV3 } from 'core-lib';
import { orderBy } from 'lodash';

export function deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}

export function md5Hash(code: string = new Date().toString()) {
    return Md5.hashStr(code);
}

/**
 * Create an anchor element and initiate a file download.
 *
 * @param data
 * @param fileType example: 'text/csv'
 * @param title needs to end with an extension
 */
export function downloadFile(data: any, fileType: string = 'application/pdf', title: string) {
    const blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, `${title}`);
}

export function downloadFileUrl(data: string) {
    window.open(data);
}

/**
 * Gets the percentage value for a number.
 *
 * Formula `(n - min) / (max - min) * 100`
 *
 * @param value
 * @param min
 * @param max
 */
export function getPercentage(value: number, min = 0, max = 100): number {
    return Math.abs(((value - min) / (max - min)) * 100);
}

/**
 * Capitalises every letter of the given string
 *
 * @param value
 */
export function capitalise(value: string): string {
    const str = value.split(' ');

    for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(' ');
}

/**
 * Convert base64 string to a Blob type
 *
 * @param str
 */
export function base64ImageToBlob(str: string) {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);

    // decode base64
    const imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type: type });

    return blob;
}

export function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/*
 * uuid4 generator
 */
export function uuidv4() {
    const hex = [...Array(256).keys()].map((index) => index.toString(16).padStart(2, '0'));

    const r = crypto.getRandomValues(new Uint8Array(16));

    r[6] = (r[6] & 0x0f) | 0x40;
    r[8] = (r[8] & 0x3f) | 0x80;

    return [...r.entries()].map(([index, int]) => ([4, 6, 8, 10].includes(index) ? `-${hex[int]}` : hex[int])).join('');
}

export function getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
}

/**
 * Filters out keys from object and returns the
 * object without those keys, uses deepCopy not to
 * modify the original object
 * @param obj => input object
 * @param keys => Array of keys to remove
 */
export function filterKeys(obj, keys = []) {
    obj = deepCopy(obj);
    keys.forEach((key) => delete obj[key]);
    return obj;
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export function mergeDeep(...objects) {
    const isObject = (obj) => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach((key) => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            } else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}

export function buildQueryParams(obj: ApiQueryParams | PaginationQueryParams): string {
    let queryString: string = '';
    if (obj) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                queryString = `${queryString}${key}=${obj[key]}&`;
            }
        });
        queryString = queryString.slice(0, -1);
    }
    return queryString;
}

export function downloadChart(chartId: string, format: 'svg' | 'png' = 'svg'): void {
    const svgElement = document.getElementById(chartId)?.getElementsByTagName('svg')[0];
    if (!svgElement) {
        console.error('SVG element not found!');
        return;
    }

    if (format === 'svg') {
        // Download as SVG
        let data = new XMLSerializer().serializeToString(svgElement);
        let svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
        let url = URL.createObjectURL(svgBlob);
        triggerDownload(url, chartId + '.svg');
    } else if (format === 'png') {
        // Convert SVG to PNG
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const svgUrl = URL.createObjectURL(svgBlob);

        const image = new Image();
        image.onload = () => {
            // Set canvas size to match the SVG's dimensions
            canvas.width = svgElement.clientWidth || 500; // Default size if not defined
            canvas.height = svgElement.clientHeight || 500;

            // Draw SVG onto the canvas
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

            // Convert canvas to PNG and trigger download
            const pngUrl = canvas.toDataURL('image/png');
            triggerDownload(pngUrl, chartId + '.png');

            // Cleanup
            URL.revokeObjectURL(svgUrl);
        };
        image.src = svgUrl;
    }
}

function triggerDownload(url: string, filename: string): void {
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    URL.revokeObjectURL(url);
}

export function createCookieExpiryDate(convertToString?: boolean): string | Date {
    let date = new Date();
    date.setTime(date.getTime() + 1200 * 24 * 60 * 60 * 1000);

    let returnValue: string | Date;
    if (convertToString) {
        returnValue = date.toUTCString();
    } else {
        returnValue = date;
    }
    return returnValue;
}

export function isMobile(width: number): boolean {
    return width <= 1024;
}

export function getUserInitials(user: UserV3): string {
    let initials = '';
    if (user.first_name || user.last_name) {
        initials = (user.first_name ? user.first_name.charAt(0) : '') + (user.last_name ? user.last_name.charAt(0) : '');
    } else if (user.email) {
        initials = user.email.charAt(0) + user.email.charAt(1);
    }
    return initials;
}

export function emailDomainCheck(email: string, domains: string[]): boolean {
    if (!email || email === '') {
        return null;
    }
    const emailDomain: string = email.substring(email.lastIndexOf('@') + 1);
    return domains.some((domain) => emailDomain.toLowerCase() === domain.toLowerCase());
}

export function getRandomInt(min: number, max: number): number {
    // Ensure the range is inclusive of the max value
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function isValidHttpUrl(string): boolean {
    try {
        const url = new URL(string);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
        return false;
    }
}

export function timeDuration(fromDate: Date, toDate: Date): { months: number; days: number; hours: number; minutes: number; seconds: number } {
    const diffInMilliseconds = toDate.getTime() - fromDate.getTime();

    // Convert milliseconds to days, hours, minutes, and seconds
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const months = Math.floor(diffInSeconds / (30 * 24 * 60 * 60));
    const days = Math.floor(diffInSeconds / (24 * 60 * 60));
    const hours = Math.floor((diffInSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    return { months, days, hours, minutes, seconds };
}

export function getGmtOffsetString(timeZone: string, locale?: string): string {
    const dateWithGmtOffsetString = new Date().toLocaleString(locale, { timeZone: timeZone, timeZoneName: 'longOffset' });
    const gmtOffset = dateWithGmtOffsetString.match(/([+-]\d{2}):(\d{2})/)[0];
    return gmtOffset.replace(':', '');
}

export function sortArrayBySearchTerm<T>(items: T[], compareKey: string, searchTerm: string): T[] {
    const normalizedSearchTerm = searchTerm.toLowerCase();

    function getValueByPath(obj: any, path: string) {
        return path.split('.').reduce((o, p) => (o ? o[p] : undefined), obj);
    }

    // Map items to include score and percent based on the search term
    const itemsWithScore = items.map((item) => {
        const itemValue = String(getValueByPath(item, compareKey)).toLowerCase();
        let score = 0;
        let percent = 0;

        if (itemValue === normalizedSearchTerm) {
            score = 1000;
            percent = 100;
        } else if (itemValue.startsWith(normalizedSearchTerm)) {
            score = 500 + (normalizedSearchTerm.length / itemValue.length) * 100;
            percent = (normalizedSearchTerm.length / itemValue.length) * 100;
        } else if (itemValue.includes(normalizedSearchTerm)) {
            score = 100 + (normalizedSearchTerm.length / itemValue.length) * 100;
            percent = (normalizedSearchTerm.length / itemValue.length) * 100;
        }

        return { ...item, _score: score, _percent: percent };
    });

    // Sort by score and percent in descending order
    return orderBy(itemsWithScore, ['_score', '_percent'], ['desc', 'desc']);
}

export function convertTimestampToDayString(timestamp: number): string {
    const now = new Date();
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return '1 day ago';
    } else {
        return `${diffDays} days ago`;
    }
}

export function getEnumKeyByValue(enumList: any, value: any): string {
    return Object.keys(enumList).find((key) => enumList[key as keyof typeof enumList] === value);
}

export function stripHtmlTags(input: string): string {
    return input.replace(/<[^>]*>/g, '');
}
