import { ErrorHandler, Injectable, Provider } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error) {
        const eventId = Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });
    }
}

export const initSentry = (environment: any) => {
    if (environment.ravenDsn) {
        Sentry.init({
            beforeSend(event, hint) {
                const exception = hint.originalException;
                if (exception && exception === 'Timeout') return null;
                if (exception && hint.originalException.toString().match('originalPrompt')) return null;
                return event;
            },
            dsn: environment.ravenDsn,
            environment: environment.production ? 'prod' : 'dev',
            ignoreErrors: ['top.GLOBALS', 'Non-Error exception captured', 'Unhandled Promise rejection', 'Uncaught (in promise) Timeout', 'ExpressionChangedAfterItHasBeenCheckedError', 'pktAnnotationHighlighter', 'ResizeObserver loop limit exceeded']
        });
    }
};

export const provideSentryErrorHandler = (environment: any): Provider => {
    if (environment.ravenDsn) {
        return { provide: ErrorHandler, useClass: SentryErrorHandler };
    } else {
        return [];
    }
};
