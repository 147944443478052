export enum DsModalSizeEnum {
    S = 's',
    M = 'm',
    L = 'l',
    XL = 'xl',
    FULL = 'full',
    CUSTOM = 'custom'
}

export interface DsModalModel {
    id: string;
    isOpen: boolean;
    result: any;
}

export interface DsModalCloseObject {
    id: string;
    result: any;
}

export interface DsModalScrollObject {
    top: number;
    scrollHeight: number;
    clientHeight: number;
    bottom: number;
}
// @todo - see with Matej what we are going to do about modalenum
export enum ModalEnum {
    ProfileChangePasswordModal = 'change-password-modal',
    ProfileChangeEmailModal = 'change-email-modal',
    ProfileAreYouSureDeleteAccountModal = 'are-you-sure-delete-user-modal',
    ProfileAreYouSureLogoutFromAllModal = 'are-you-sure-logout-user-modal',
    UserAssessmentAssignAsessmentModal = 'assign-assessment-modal',
    LoginTwoWayAuthModal = 'two-way-auth-modal',
    QuizRetakeModal = 'retake-quiz-modal',
    QuizGuardLeaveModal = 'quiz-guard-leave-modal',
    LoginChooseYourAccountModal = 'login-choose-user-account-modal',
    UserAssessmentDeleteAssessmentResultsModal = 'delete-assessment-modal',
    AnonLoginRegisterModal = 'anon-login-register-modal',
    CopyValueModal = 'copy-value-modal',
    AnonLoginOverwriteResultsModal = 'anon-login-overwrite-results-modal',
    UserDetailsManagementCopyUserModal = 'copy-user-modal',
    UserDetailsManagemntConfirmDeleteUserModal = 'confirm-delete-user-modal',
    AccountDetailsWebhookAssessmentModal = 'account-detail-webhook-assessment-modal',
    AccountDetailsUploadEchartThemeModal = 'account-detail-upload-echarts-theme-dialog',
    AccountDetailsUploadCompanyLogoModal = 'account-detail-upload-company-log-dialog',
    AccountDetailsCustomizeColorsModal = 'account-details-customize-colors-modal',
    AccountDetailsDeleteAccountModal = 'account-details-delete-account-modal',
    AccountDetailsTermsAndConditionsModal = 'account-details-terms-and-conditions-modal',
    InviteTemplateAreYouSureDeleteModal = 'invite-template-are-you-sure-delete-modal',
    AssessmentSettingsUpdateAssessmentNameModal = 'assessment-settings-update-assessment-name-modal',
    AssessmentSettingsUpdateQueAndSettingsModal = 'assessment-settings-update-que-and-settings-modal',
    AssessmentSettingsUpdateAssessmentIconModal = 'assessment-settings-update-assessment-icon-modal',
    AssessmentSettingsUpdateSocialMediaImageModal = 'assessment-settings-update-social-media-image-modal',
    AssessmentSettingsCoonfirmDeleteAssessmentModal = 'assessment-settings-confirm-delete-assessment-modal',
    RemoveUsersFromGroupModal = 'remove-users-from-group-modal',
    TrackEmailsEmailsPreviewModal = 'emails-preview-modal',
    UserReportCopyValueModal = 'user-report-copy-value-modal',
    LearningJourneyManagemntCoreDriverDialogModal = 'learning-journey-management-core-driver-dialog-modal',
    AddNewUserModal = 'add-newuser-modal',
    DeleteTemplateModalAreYouSureModal = 'are-you-sure-delete-template',
    ArticleListAddToLocalDbModal = 'article-list-add-locale-to-db-modal',
    ArticleLibraryListCreateArticleLibraryModal = 'create-article-library-modal',
    ArticleLibraryListDuplicateLibraryModal = 'duplicate-article-library-modal',
    ArticleLibraryListAreYouSureMakeDefaultLibModal = 'sure-make-default-lib-modal',
    ArticleLibraryListAreYouSureToDeleteLibModal = 'sure-delete-article-lib-modal',
    AssessmentResponseAddUserToGroupModal = 'assessment-responses-add-user-to-group-modal',
    AssessmentResponseRemoveUserFromGroupModal = 'assessment-responses-remove-user-from-group-modal',
    AssessmentResponseGenerateCsvFileModal = 'assessment-responses-generate-csv-file-modal',
    AssessmentResponseTranslateModal = 'assessment-responses-translate-modal',
    AccountDetailsAddAssessmentLibraryModal = 'account-details-add-assessment-library-modal',
    UserReportInviteModal = 'user-assesment-report-invite-modal',
    InsioghtsSettingsLibraryModalId = 'settings-insight-library-modal',
    ChartTypeModal = 'chart-type-modal',
    ChartFieldOptionsModal = 'chart-field-edit-modal',
    AnalyticSettingsAssessmentLibrariesModalId = 'analytics_settings_assessment_libraries_modal_id',
    AnalyticsDashboardDeleteChartModalId = 'analytics-dashboard-delete-chart-confirmation-id',
    AnalyticSettingsDeleteAnalyticsModalId = 'analytics-settings-delete-analytics-modal-id',
    AnalayticsHrisModalId = 'analytics-hris-modal-id',
    AnalayticsHrisDeleteModalId = 'analytics-hris-delete-modal-id',
    SidenavAddNewAccountModalId = 'add-new-account-modal-id',
    CreateAssessmentModalId = 'create-assessment-modal-id',
    UserDetailsOnAddUserToProject = 'modal-inside-modal-user-details-id',
    DeleteCardsStreamLibraryConfirmationModalId = 'delete-cards-stream-library-confirmation-modal-id',
    CreateOrEditCardsStreamLibraryModalId = 'create-or-edit-cards-stream-library-modal-id',
    UnsplashImagesModalId = 'unsplash-images-modal-id',
    DeleteCardModalId = 'delete-card-modal-id',
    DynamoLibraryAssignmentModalId = 'dynamo-library-assignment-modal-id',
    AddPlaybookModal = 'add-playbook-modal',
    CreateGroupModal = 'create-group-modal',
    DeleteGroupConfirmationModal = 'delete-group-confirmation-modal',
    GenerateApiKeys = 'generate-api-keys',
    ViewApiKeys = 'view-api-keys',
    DeleteIdpConfigConfirmation = 'delete-idp-config-confirmation',
    DeletePlaybookConfirmationModal = 'delete-playbook-confirmation-modal',
    DeleteApiKeyConfirmationModal = 'delete-api-key-confirmation-modal',
    CardsCrowdinSyncFromModal = 'cards-crowdin-sync-from-modal',
    CardsCrowdinSyncToModal = 'cards-crowdin-sync-to-modal',
    ChangeUserRoleConfirmationModal = 'change-user-role-confirmation-modal',
    UploadTeamsModal = 'upload-teams-modal',
    DeleteTeamsConfirmationModal = 'delete-teams-confirmation-modal',
    TeamsFilterModal = 'teams-filter-modal',
    UploadUsersModal = 'upload-users-modal',
    TeamLibrariesAssignmentModal = 'team-libraries-assignment-modal',
    ProfileAreYouSureLogOutFromAllModal = 'profile-are-you-sure-log-out-from-all-modal',
    EChartsUploadThemeModal = 'eCharts-upload-theme-modal',
    DeleteUserConfirmationModal = 'delete-user-confirmation-modal',
    DeleteUserConfirmationDialogModal = 'delete-user-confirmation-dialog-component',
    CreateWebhookModal = 'create-webhook-modal',
    DeleteUacfConfirmationModal = 'delete-uacf-confirmation-modal',
    AnalyticsCompetencyAddUserToGroupModal = 'analytics-competency-add-user-to-group-modal',
    AnalyticsCompetencyRemoveUserToGroupModal = 'analytics-competency-remove-user-to-group-modal',
    LearningJourneyModal = 'learning-journey-modal',
    DeleteAllUnassignedGroups = 'delete-all-unassigned-groups',
    AddNewGroupModal = 'add-new-group-modal',
    DownloadUsersPdfModal = 'download-users-pdf-modal',
    DeleteUsersModal = 'delete-users-modal',
    DimensionFilterNameModal = 'dimension-filter-name-modal-id',
    DeleteChartModal = 'delete-chart-modal-id',
    InsightsSettingsLibraryModal = 'settings-insight-library-modal',
    DeleteRoleModal = 'delete-role-modal',
    ScoreFilterModal = 'score-filter-modal',
    AssessmentSettingsQuestionLibraryModal = 'AssessmentSettingsQuestionLibraryModal',
    AssessmentSettingsReportLibraryModal = 'AssessmentSettingsReportModal',
    AssessmentSettingsArticleLibraryModal = 'AssessmentSettingsArticleLibraryModal',
    AssessmentSettingsInsightsLibraryModal = 'AssessmentSettingsInsightsLibraryModal',
    AssessmentSettingsCompetencyLibraryModal = 'AssessmentSettingsCompetencyLibraryModal',
    AssignRoleModal = 'AssignRoleModal',
    UserRolesToggleConfirmationModal = 'UserRolesToggleConfirmationModal',
    CreateNewGroupModal = 'CreateNewGroupModal',
    SendLinkCreateNewGroup = 'SendLinkCreateNewGroup',
    SendEmailCreateNewGroup = 'SendEmailCreateNewGroup',
    AnalyticsCreateNewGroup = 'AnalyticsCreateNewGroup',
    CompetencyDashboardSettingsModal = 'dashboard-settings-competency-modal',
    CreateEditContentTypeFromSettingsModal = 'create-edit-content-type-from-settings-modal',
    EditIndividualCompetencyContentLibraryByLevel = 'edit-individual-competency-content-library-by-level-modal',
    DeleteCompetencyContentLibraryFromContentLibraries = 'delete-competency-content-library-from-content-libraries',
    UserTableFilterModal = 'UserTableFilterModal',
    ResultTableFilterModal = 'ResultTableFilterModal',
    AnalyticsTableFilterModal = 'AnalyticsTableFilterModal',
    ProjectFilterModal = 'ProjectFilterModal',
    TeamTableFilterModal = 'TeamTableFilterModal',
    StreamLibraryCardFilterModal = 'StreamLibraryCardFilterModal',
    CompetencyDashboardFilterModal = 'CompetencyDashboardFilterModal',
    CompetenciesContentLibraryFilterModal = 'CompetenciesContentLibraryFilterModal',
    CreateOrEditDynamoLibraryModal = 'CreateOrEditDynamoLibraryModal',
    DeleteDynamoLibraryConfirmationModal = 'DeleteDynamoLibraryConfirmationModal',
    CreateOrEditDynamoGoalModal = 'CreateOrEditDynamoGoalModal',
    DeleteDynamoGoalConfirmationModal = 'DeleteDynamoGoalConfirmationModal',
    DynamoGoalsFilterModal = 'DynamoGoalsFilterModal',
    UpdateDynamoLibraryNameModal = 'UpdateDynamoLibraryNameModal',
    DynamoCardsSyncToCrowdinModal = 'DynamoCardsSyncToCrowdinModal',
    DynamoCardsSyncFromCrowdinModal = 'DynamoCardsSyncFromCrowdinModal',
    MergeUserModal = 'MergeUserModal',
    MergeUserConfirmationModal = 'MergeUserConfirmationModal'
}
