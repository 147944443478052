import { Component, OnInit } from '@angular/core';
import { CookieIdsEnum, createCookieExpiryDate, getCookie, intercomActions, uuidv4 } from 'core-lib';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'projects/auth/src/environments/environment';

@Component({
    selector: 'ds-auth-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    get isIe() {
        return window.navigator.userAgent.match(/Trident/);
    }

    constructor(private cookieService: CookieService) {}

    ngOnInit(): void {
        if (environment.intercom.enabled) {
            intercomActions.boot();
        }
        // if (environment.enableFaroGraphana) {
        //   loadScript("assets/js/faro.js");
        // }

        if (!window.location.href.includes('sso') && !window.location.href.includes('localhost')) {
            const cookies = document.createElement('script');
            cookies.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js';
            cookies.setAttribute('type', 'text/javascript');
            cookies.setAttribute('charset', 'UTF-8');
            cookies.setAttribute('data-domain-script', '2bd7e454-78ec-4c5c-a0e4-df9d0abc6e2f');
            cookies.setAttribute('nonce', 'YUhhP7LLyCLlmLk74qXvWvtpDa8eH/p9ZwUT/G96C+XJdpPsAyacixat+37a0Toi');

            const cookies2 = document.createElement('script');
            cookies2.setAttribute('type', 'text/javascript');
            cookies2.setAttribute('nonce', 'YUhhP7LLyCLlmLk74qXvWvtpDa8eH/p9ZwUT/G96C+XJdpPsAyacixat+37a0Toi');
            cookies2.innerText = `function OptanonWrapper() { }`;
            document.body.appendChild(cookies);
            document.body.appendChild(cookies2);
        }

        let ident = uuidv4();
        if (!getCookie(CookieIdsEnum.SessionTracking)) {
            this.cookieService.set(CookieIdsEnum.SessionTracking, ident, createCookieExpiryDate() as Date, '/');
        }
    }
}
