import { PixelConfiguration } from './pixel.models';
import { Inject, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PixelService } from './pixel.service';

@NgModule({
    imports: []
})
export class PixelModule {
    private static config: PixelConfiguration | null = null;

    constructor(
        private pixel: PixelService,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        if (!PixelModule.config) {
            throw Error(' not configured correctly. Pass the `pixelId` property to the `forRoot()` function');
        }
        if (PixelModule.config.enabled && PixelModule.config.pluginEnabled && isPlatformBrowser(platformId)) {
            this.pixel.initialize();
        }
    }

    /**
     * @description
     * Initiale the Facebook Pixel Module
     * Add your Pixel ID as parameter
     */
    static forRoot(config: PixelConfiguration): ModuleWithProviders<PixelModule> {
        this.config = config;
        if (config.pluginEnabled) {
            const pixelId = config.pixelId;
            this.verifyPixelId(pixelId);
        }
        return {
            ngModule: PixelModule,
            providers: [PixelService, { provide: 'config', useValue: config }]
        };
    }

    /**
     * @description
     * Verifies the Pixel ID that was passed into the configuration.
     * - Checks if Pixel was initialized
     * @param pixelId Pixel ID to verify
     */
    private static verifyPixelId(pixelId: string[]): void {
        // Have to verify first that all Pixel IDs follow the same 15 digit format
        pixelId.forEach((id) => {
            if (id === null || id === undefined || id.length === 0) {
                throw Error('Invalid Facebook Pixel ID. Did you pass the ID into the forRoot() function?');
            }
        });
    }
}
