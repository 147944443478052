import { Injectable, Inject } from '@angular/core';
import { CORE_LIB_CONFIG, CoreLibConfig } from './core-lib.config';

@Injectable({
    providedIn: 'root'
})
export class CoreLibConfigService {
    private static _config: CoreLibConfig;

    constructor(@Inject(CORE_LIB_CONFIG) config: CoreLibConfig) {
        CoreLibConfigService._config = config;
    }

    static getConfig(): CoreLibConfig {
        return CoreLibConfigService._config;
    }
}
