import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, WritableSignal, signal } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { DOCUMENT, NgClass, NgIf, NgStyle } from '@angular/common';

import { DsModalScrollObject, DsModalSizeEnum } from './ds-modal.model';
import { DsModalService } from './ds-modal.service';

@Component({
    selector: 'ds-modal',
    templateUrl: './ds-modal.component.html',
    standalone: true,
    imports: [NgClass, NgIf, NgStyle]
})
export class DsModalComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<void>();

    @Input() id!: string;
    @Input() backdropClose: boolean = true;
    @Input() showCloseButton: boolean = false;
    @Input() size: DsModalSizeEnum = DsModalSizeEnum.M;
    @Input() level = 1;
    @Input() mobileFullScreen: boolean = false;
    @Input() calculateMobileHeight: boolean = true;
    @Input() enableScrollEvent: boolean = false;
    @Input() mobileHeightMultiplier: number = 0.94;
    @Input() backgroundClass: string = 'bg-gray-500 bg-opacity-75 ease-in-out duration-500';
    @Input() mainWrapperClasses: string = 'fixed inset-0 overflow-y-auto';
    @Input() modalSizeClassses: string = '';
    @Input() paddingClasses: string;
    @Input() sizeClasses: string;
    @Input() zIndex: string = 'z-40';
    @Input() closeButtonClasses: string = 'bg-white cursor-pointer rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500';
    private element: any;

    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
    @Output() onScroll: EventEmitter<DsModalScrollObject> = new EventEmitter<DsModalScrollObject>();
    showModal: boolean = false;
    @ViewChild('modalWrapper') set filter(e: ElementRef) {
        if (!!e && this.calculateMobileHeight && window.innerWidth < 768) {
            if (e.nativeElement.scrollHeight >= window.innerHeight) {
                e.nativeElement.style.height = `${window.innerHeight * this.mobileHeightMultiplier}px`;
                e.nativeElement.style.marginBottom = '4rem';
            }
        }
    }

    isOverflowClassBeenAddedByModal: WritableSignal<boolean> = signal(false);
    constructor(
        private modalService: DsModalService,
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        if (this.zIndex) this.mainWrapperClasses += ` ${this.zIndex}`;
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        if (this.backdropClose) {
            this.backgroundClass += ' cursor-pointer';
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        //listen for modal open event
        //actually this is going to work also modal in modal because this open method needs to be called only once
        this.modalService.openModal$
            .pipe(
                filter((modalId) => modalId === this.id),
                takeUntil(this.destroy$)
            )
            .subscribe((_) => {
                this.open();
            });

        //listen for modal close event
        this.modalService.closeModal$
            .pipe(
                filter((closeModal) => closeModal?.id === this.id),
                takeUntil(this.destroy$)
            )
            .subscribe((closeResult) => {
                this.close(closeResult.result);
            });
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.destroyAll();
        this.element.remove();
        this.destroy$.next();
        this.destroy$.complete();
    }

    onModalScroll(event: Event): void {
        if (!this.enableScrollEvent) return;
        const target = event.target as HTMLDivElement;
        if (!!target && target instanceof HTMLDivElement) {
            let scroll: DsModalScrollObject = {
                top: target.scrollHeight,
                scrollHeight: target.scrollHeight,
                clientHeight: target.clientHeight,
                bottom: target.scrollHeight - target.clientHeight - target.scrollTop
            };
            this.onScroll.emit(scroll);
        }
    }
    // open modal
    open(): void {
        if (!document.body.classList.contains('overflow-hidden')) {
            this.isOverflowClassBeenAddedByModal.set(true);
            this.renderer.addClass(this.document.body, 'overflow-hidden');
        }
        this.element.style.display = 'block';
        this.showModal = true;
        this.onOpen.emit();
    }

    // close modal
    close(result?: any): void {
        if (this.isOverflowClassBeenAddedByModal()) {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
        }
        this.element.style.display = 'none';
        this.showModal = false;
        this.onClose.emit(result);
    }

    onBackgroundClose(): void {
        if (this.backdropClose) {
            this.modalService.close(this.id);
        }
    }

    buttonClose(): void {
        this.modalService.close(this.id);
    }

    getModalClasses(): string {
        switch (this.size) {
            case DsModalSizeEnum.S:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full lg:h-full lg:max-w-md lg:px-4 lg:rounded-lg lg:pt-5 lg:pb-4 lg:max-h-93vh';
                } else {
                    if (!!this.paddingClasses) {
                        return `lg:max-h-93vh rounded-lg max-w-md ${this.paddingClasses}`;
                    }
                    return 'lg:max-h-93vh rounded-lg px-4 pt-5 pb-4 max-w-md sm:my-8 sm:p-6';
                }
            case DsModalSizeEnum.M:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full lg:h-full lg:max-w-xl lg:px-4 lg:rounded-lg lg:pt-5 lg:pb-4 lg:max-h-93vh';
                } else {
                    if (!!this.paddingClasses) {
                        return `lg:max-h-93vh rounded-lg max-w-xl ${this.paddingClasses}`;
                    }
                    return 'lg:max-h-93vh rounded-lg px-4 pt-5 pb-4 max-w-xl sm:my-8 sm:p-6';
                }
            case DsModalSizeEnum.L:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full lg:h-full lg:px-4 lg:rounded-lg lg:pt-5 lg:pb-4 lg:max-h-93vh lg:max-w-85-percent';
                } else {
                    if (!!this.paddingClasses) {
                        return `lg:max-h-93vh rounded-lg max-w-85-percent ${this.paddingClasses}`;
                    }
                    return 'lg:max-h-93vh rounded-lg px-4 pt-5 pb-4 max-w-85-percent sm:my-8 sm:p-6';
                }
            case DsModalSizeEnum.XL:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full lg:px-4 lg:rounded-lg lg:pt-5 lg:pb-4 lg:max-h-93vh lg:max-w-98vw';
                } else {
                    if (!!this.paddingClasses) {
                        return `lg:max-h-93vh rounded-lg max-w-95-percent ${this.paddingClasses}`;
                    }
                    return 'lg:max-h-93vh rounded-lg px-4 pt-5 pb-4 max-w-95-percent sm:my-8 sm:p-10 lg:max-w-98vw';
                }
            case DsModalSizeEnum.FULL:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full';
                } else {
                    if (!!this.paddingClasses) {
                        return `h-screen ${this.paddingClasses}`;
                    }
                    return 'h-screen px-4 pt-5 pb-4 ';
                }
            case DsModalSizeEnum.CUSTOM:
                return this.sizeClasses;
            default:
                if (this.mobileFullScreen) {
                    return 'h-screen p-2 w-full lg:h-full lg:max-w-xl lg:px-4 lg:rounded-lg lg:pt-5 lg:pb-4 lg:max-h-93vh';
                } else {
                    if (!!this.paddingClasses) {
                        return `lg:max-h-93vh rounded-lg max-w-xl ${this.paddingClasses}`;
                    }
                    return 'lg:max-h-93vh rounded-lg px-4 pt-5 pb-4 max-w-xl sm:my-8 sm:p-6';
                }
        }
    }

    getSizeModalClasses(): string {
        if (!!this.modalSizeClassses) return this.modalSizeClassses;
        if (this.mobileFullScreen) {
            return 'lg:px-4';
        } else if (this.size !== 'full') {
            return 'pt-4 px-4 pb-24';
        } else {
            return null;
        }
    }
}
