import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, Signal, WritableSignal, inject, input, signal } from '@angular/core';
import { filter } from 'rxjs/operators';
import { LanguageModel, LanguageSwitcherReducers } from 'core-lib';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DsIconEnum, DsIconWeightEnum, DsModalService } from '@deepersignals/components';
import { LanguageSwitcherModalEnum } from '../models/language-switcher.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ds-language-switcher',
    templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent implements OnInit {
    @Input() enableUserLocaleUpdate: boolean = true;
    LanguageSwitcherModalEnum = LanguageSwitcherModalEnum;
    DsIconEnum = DsIconEnum;
    DsIconWeightEnum = DsIconWeightEnum;

    availableLanguages: Signal<LanguageModel[]> = this.store.selectSignal(LanguageSwitcherReducers.selectAvailableLanguages);

    currentLanguageName: WritableSignal<string> = signal(this.getLanguageNameByCode(this.translateService.currentLang));
    isChooseLanguageModalOpen: WritableSignal<boolean> = signal(false);

    isMenuColapsed = input<boolean>();

    @Output() isLanguageSwitcherOpen: EventEmitter<boolean> = new EventEmitter<boolean>(null);
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        private store: Store,
        private modalService: DsModalService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.translateService.onLangChange
            .pipe(
                filter((x) => !!x),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((langChange: LangChangeEvent) => {
                this.currentLanguageName.set(this.getLanguageNameByCode(langChange.lang));
            });
    }

    openLanguageModal(): void {
        this.isLanguageSwitcherOpen.emit(true);
        this.isChooseLanguageModalOpen.set(true);
        this.modalService.open(LanguageSwitcherModalEnum.ChooseLanguageModal);
    }

    onLanguageModalClose(): void {
        this.isLanguageSwitcherOpen.emit(false);
        this.isChooseLanguageModalOpen.set(false);
    }

    getLanguageNameByCode(languageCode: string): string {
        return this.availableLanguages().find((x) => x.code === languageCode)?.name;
    }
}
